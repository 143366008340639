import {Fragment, useEffect, useState} from "react";
import NavigationComponent from "../components/navigation/NavigationComponent";
import RealEstatePreviewContainer from "../components/real-estate-listing-preview-container/RealEstatePreviewContainer";
import SearchHeroComponent from "../components/search-hero/SearchHeroComponent";
import FooterComponent from "../components/footer/FooterComponent";
import DividerComponent from "../components/divider/DividerComponent";
import {pretraziIstaknute} from "../api";
import SliderComponentDesktop from "../components/slider-component/desktop/SliderComponentDesktop";

const HomePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Gorica Nekretnine - pronađite savršenu nekretninu";
    }, []);

    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        pretraziIstaknute().then(data => {
            setListings(data);
            setLoading(false);
        }).catch(err => {
            console.error("Error fetching listings:", err);
            setLoading(false);
        });
    }, []);


    return (
        <Fragment>
            <NavigationComponent currentPage={"home"}></NavigationComponent>
            <SearchHeroComponent/>
            <DividerComponent color={"#164158"} marginTop={40} marginBottom={0}/>

            <RealEstatePreviewContainer listings={listings} headline={"Istaknute nekretnine"}
                                        paragraphText={"Pogledajte naše preporuke"} isLoading={loading}/>
            <DividerComponent color={"#164158"} marginTop={15} marginBottom={15}/>
            <SliderComponentDesktop/>
            <FooterComponent/>
        </Fragment>
    );

};

export default HomePage;
