import {Fragment, useEffect, useState} from "react";
import NavigationComponent from "../components/navigation/NavigationComponent";
import FooterComponent from "../components/footer/FooterComponent";
import RealEstateListingFullView from "../components/real-estate-listing-full-view/RealEstateListingFullView";
import LoadingOverlay from "../components/spinner/LoadingOverlay";

const RealEstateListingFullViewPage = () => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);

        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <LoadingOverlay isLoading={isLoading}/>
            <Fragment>
                <NavigationComponent/>
                <RealEstateListingFullView/>
                <FooterComponent/>
            </Fragment>
        </>
    );
};

export default RealEstateListingFullViewPage;
