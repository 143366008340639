import React from "react";
import "./ListingViewMobile.css";
import {Icon} from "semantic-ui-react";
import Carousel from "../../carousel/Carousel";
import {KUCA, POSLOVNI_PROSTOR, STAN, STAROGRADNJA, ZEMLJISTE} from "../../../../mockData";
import {Link} from "react-router-dom";

const ListingViewMobile = (props) => {
    const images = props.images;
    const listing = props.listing;

    function generateMobileIcons() {
        switch (listing.additionalInfo.type) {
            case KUCA: {
                return (
                    <div className="mobile-info-icons">
                        <p>{listing.additionalInfo.livingArea} m² <Icon name="home"/></p>
                        <p>{listing.additionalInfo.landArea} m² <Icon name="expand"/></p>
                        <p>{listing.additionalInfo.bedrooms} <Icon name="bed"/></p>
                        <p>{listing.additionalInfo.bathrooms} <Icon name="bath"/></p>
                    </div>
                )
            }
            case POSLOVNI_PROSTOR: {
                return (
                    <div className="mobile-info-icons">
                        <p>{listing.additionalInfo.livingArea} m² <Icon name="home"/></p>
                    </div>
                )
            }
            case STAN: {
                return (
                    <>
                        <div className="mobile-info-icons">
                            <p>{listing.additionalInfo.livingArea} m² <Icon name="external square"/></p>
                            <p>{listing.additionalInfo.bedrooms} <Icon name="bed"/></p>
                            <p>{listing.additionalInfo.bathrooms} <Icon name="bath"/></p>
                        </div>
                        <div className="mobile-info-icons">
                            <p>{listing.additionalInfo.elevator ? <span>Dizalo: da</span> : <span>Dizalo: ne</span>}</p>
                            <p>{listing.additionalInfo.balcony ? <span>Balkon/terasa: da</span> :
                                <span>Balkon/terasa: ne</span>}</p>
                        </div>
                        </>
                )
            }

            case ZEMLJISTE: {
                return null
            }
            default: return null
        }
    }

    function mobilePricingLeft() {
        return (
            <>
                <p>Cijena po m2: </p>
                <p>Kategorija: </p>
                <p>Vrsta: </p>
                {listing.additionalInfo.construction && <p>Gradnja: </p>}
                {listing.additionalInfo.construction && <p>Godina gradnje: </p>}
                {(listing.additionalInfo.construction && listing.additionalInfo.construction.constructionType
                    && listing.additionalInfo.construction.constructionType === STAROGRADNJA) &&
                    <p>Godina renovacije: </p>
                }

            </>
        )
    }

    function renderTypeMobile() {
        switch (listing.additionalInfo.type) {
            case "KUCA": {
                switch (listing.additionalInfo.subType) {
                    case "VIKENDICA": return "Vikendica"
                    case "KUCA_ZA_ODMOR": return "Kuća za odmor"
                    case "VILLA": return "Villa"
                    default: return "Kuća"
                }

            }
            case "POSLOVNI_PROSTOR": {
                return "Poslovni prostor"
            }
            case "STAN": {
                switch (listing.additionalInfo.subType) {
                    case "APARTMAN": return "Apartman"
                    default: return "Stan"
                }
            }
            case "ZEMLJISTE": {
                return "Zemljište"
            }
            default: return null
        }
    }

    function mobilePricingRight() {
        return (
            <>
                <p>

                    {listing.additionalInfo.livingArea ?
                        (listing.price / listing.additionalInfo.livingArea).toFixed(2)
                        :
                        (listing.price / listing.additionalInfo.landArea).toFixed(2)
                    }
                    {" "} €

                </p>
                <p>
                    {listing.category.charAt(0).toUpperCase() +
                        listing.category.slice(1).toLowerCase()}
                </p>
                <p>
                    {renderTypeMobile()}
                </p>

                { listing.additionalInfo.construction &&
                    <p>
                        {listing.additionalInfo.construction.constructionType.charAt(0).toUpperCase() +
                            listing.additionalInfo.construction.constructionType.slice(1).toLowerCase()}
                    </p>
                }

                { listing.additionalInfo.construction &&
                    <p>
                        {listing.additionalInfo.construction.constructionYear}.
                    </p>
                }

                {(listing.additionalInfo.construction && listing.additionalInfo.construction.constructionType
                        && listing.additionalInfo.construction.constructionType === STAROGRADNJA) &&
                    <p>{listing.additionalInfo.construction.renovationYear}.</p>
                }
            </>
        )
    }

    const createGridItems = (array) => {
        return array.map((item, index) => <p key={index}>{item}</p>);
    };

    return (
        <>
            <Carousel images={images}/>
            <div className="mobile-details-container">
                <div className="mobile-header">
                    <div className="mobile-title">
                        <h2>{listing.title}</h2>
                    </div>
                    <div className="mobile-price">
                        <h3>{listing.price} €</h3>
                    </div>
                </div>
                <p className="mobile-location"><Icon name={"location arrow"}/>{`${listing.location.city}, ${listing.location.neighbourhood}`}</p>


                {
                    generateMobileIcons()
                }


                <div className="mobile-pricing-info">
                    <div className="mobile-pricing-left">
                        {mobilePricingLeft()}
                    </div>
                    <div className="mobile-pricing-right">
                        {mobilePricingRight()}
                    </div>
                </div>


                <div className="mobile-oglas-opsirnije">
                    <h3>Opširnije</h3>
                    <div className="mobile-oglas-opsirnije-text"
                         dangerouslySetInnerHTML={{__html: listing.description}}></div>
                </div>

                <div className="mobile-oglas-dodatno">
                    <h3>Dodatno</h3>
                    <div className="mobile-oglas-dodatno-text">
                        {createGridItems(listing.userAddedAdditionalInfo)}
                    </div>
                </div>


                <div className="mobile-buttons">
                    <Link to={"/kontakt?id=" + listing.id}>
                        <button className="mobile-buttons-contact">Kontaktirajte nas za ovu nekretninu </button>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default ListingViewMobile;
