import {useState, useContext, useEffect} from "react";
import ListingView from "./types/ListingView";
import { Button, Icon } from "semantic-ui-react";
import AuthContext from "../../auth/auth-context";
import {useNavigate, useParams} from "react-router-dom";
import {dohvatiOglas} from "../../api";


const RealEstateListingFullView = () => {
    const { type, id} = useParams();
    const authContext = useContext(AuthContext);

    const navigate = useNavigate()

    const [listing, setListing] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dohvatiOglas(id, type).then(data => {
            setListing(data);
            setImages(data.images)
            setLoading(false);
           document.title = data.title + " - Gorica Nekretnine";
        }).catch(err => {
            console.error("Error fetching listing:", err);
            setLoading(false);
        });
    }, [id, type]);

    const navigateToEdit = () => {
        navigate("/uredi_oglas/" + listing.id)
    };

    return (
        <>
            {authContext.isLoggedIn && (

                <Button animated={"fade"}   onClick={navigateToEdit} color="red" style={{
                    margin: "15px"
                }}>
                    <Button.Content visible> Uredi oglas</Button.Content>
                    <Button.Content hidden><Icon name='right arrow' /></Button.Content>
                </Button>

            )}
            {!loading && <ListingView listing={listing} images={images} />}

        </>
    );
};

export default RealEstateListingFullView;
