import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import './LoadingOverlay.css';

const LoadingOverlay = ({ isLoading }) => {
    return (
        <CSSTransition
            in={isLoading}
            timeout={200}
            classNames="loading-overlay"
            unmountOnExit
        >
            <Segment style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                <Dimmer active inverted>
                    <Loader size='large'>Dohvaćamo nekretninu za Vas</Loader>
                </Dimmer>
            </Segment>
        </CSSTransition>
    );
};

export default LoadingOverlay;
