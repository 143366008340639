import {Link} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import "./NavigationDesktopHelper.css";
import AuthContext from "../../../../auth/auth-context";
import {useContext,} from "react";




const NavigationDesktopHelper = ({currentPage}) => {
    const authContext = useContext(AuthContext);


    return (
        <nav>
            <div className="nav-dsktp-navigation-container">
                <div className="nav-dsktp-navigation-column">
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo Gorica Nekretnine"
                             className="nav-dsktp-logo-img"/>
                    </Link>
                </div>
                <div className="nav-dsktp-navigation-column">
                    <div className="nav-dsktp-navigation-center-menu-items">
                        <Link to="/">
                <span
                    className={`nav-dsktp-navigation-cursor-hover ${
                        currentPage === "home" ? "nav-dsktp-navigation-active-page" : ""
                    }`}
                >
                  POČETNA
                </span>{" "}
                        </Link>

                        <Link to="/pretraga">
                <span
                    className={`nav-dsktp-navigation-cursor-hover ${
                        currentPage === "search" ? "nav-dsktp-navigation-active-page" : ""
                    }`}
                >
                  PRETRAGA
                </span>{" "}
                        </Link>

                        <div className="nav-dsktp-navigation-dropdown">
    <span className="nav-dsktp-navigation-cursor-hover">
        PRODAJA <Icon className="nav-dsktp-navigation-dropdown-icon" name="angle down"></Icon>
    </span>
                            <div className="nav-dsktp-navigation-dropdown-content">
                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Stan <Icon name="arrow right" size="small"/> </span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>
                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Kuća <Icon name="arrow right" size="small"/></span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>

                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Villa <Icon name="arrow right" size="small"/></span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=KUCA&podTipovi=VILLA&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=KUCA&podTipovi=VILLA&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=KUCA&podTipovi=VILLA&kategorije=PRODAJA#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=KUCA&podTipovi=VILLA&kategorije=PRODAJA#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=KUCA&podTipovi=VILLA&kategorije=PRODAJA#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>


                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Zemljišta <Icon name="arrow right" size="small"/></span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=ZEMLJISTE&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=ZEMLJISTE&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=ZEMLJISTE&kategorije=PRODAJA#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=ZEMLJISTE&kategorije=PRODAJA#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=ZEMLJISTE&kategorije=PRODAJA#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="nav-dsktp-navigation-dropdown">
    <span className="nav-dsktp-navigation-cursor-hover">
        NAJAM <Icon className="nav-dsktp-navigation-dropdown-icon" name="angle down"></Icon>
    </span>
                            <div className="nav-dsktp-navigation-dropdown-content">
                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Stan <Icon name="arrow right" size="small"/> </span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>
                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Kuća <Icon name="arrow right" size="small"/></span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>

                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Villa <Icon name="arrow right" size="small"/></span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=KUCA&podTipovi=VILLA&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=KUCA&podTipovi=VILLA&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=KUCA&podTipovi=VILLA&kategorije=NAJAM#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=KUCA&podTipovi=VILLA&kategorije=NAJAM#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=KUCA&podTipovi=VILLA&kategorije=NAJAM#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>


                                <div className="nav-dsktp-navigation-dropdown-submenu">
                                    <span>Poslovni prostor <Icon name="arrow right" size="small"/></span>
                                    <div className="nav-dsktp-navigation-dropdown-submenu-content">
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=POSLOVNI_PROSTOR&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Centar</Link>
                                        <Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=POSLOVNI_PROSTOR&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Sve</Link>
                                        <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=POSLOVNI_PROSTOR&kategorije=NAJAM#rezultati-pretrage-id">Velika Gorica</Link>
                                        <Link to="/pretraga?gradovi=Dalmacija&tipovi=POSLOVNI_PROSTOR&kategorije=NAJAM#rezultati-pretrage-id">Dalmacija</Link>
                                        <Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=POSLOVNI_PROSTOR&kategorije=NAJAM#rezultati-pretrage-id">Istra i Kvarner</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Link to="/kontakt">
                <span
                    className={`nav-dsktp-navigation-cursor-hover ${
                        currentPage === "kontakt" ? "nav-dsktp-navigation-active-page" : ""
                    }`}
                >
                  KONTAKT
                </span>{" "}
                        </Link>


                        <Link to="/cesto-postavljena-pitanja">
                <span
                    className={`nav-dsktp-navigation-cursor-hover-sticky ${
                        currentPage === "faq" ? "nav-dsktp-navigation-active-page" : ""
                    }`}
                >
                  FAQ
                </span>{" "}
                        </Link>
                    </div>
                </div>
                <div className="nav-dsktp-navigation-column">
                    {!authContext.isLoggedIn && (
                        <Link to="/prijava">
                <span
                    className={`nav-dsktp-navigation-cursor-hover ${
                        currentPage === "login" ? "nav-dsktp-navigation-active-page" : ""
                    }`}
                >
                  PRIJAVA
                </span>{" "}
                        </Link>
                    )}

                    {authContext.isLoggedIn && (
                        <Link to="/profile">
                <span
                    className={`nav-dsktp-navigation-cursor-hover ${
                        currentPage === "kontrolna-ploca"
                            ? "nav-dsktp-navigation-active-page"
                            : ""
                    }`}
                >
                  MOJ PROFIL
                </span>{" "}
                        </Link>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavigationDesktopHelper;
