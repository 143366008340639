import axios from 'axios';
import {KUCA, STAN, POSLOVNI_PROSTOR, ZEMLJISTE} from "./mockData";

export const baseURL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION = "api/v1"
const PROMOTED = "promoted"
const SEARCH = "search"
const HOUSES = "houses"
const POSLOVNI = "poslovni"
const ZEMLJISTE_API = "zemljiste"
const APARTMENTS = "apartments"
const CITIES = "cities"
const AUTH = "auth"
const IMAGE = "image"
const EMAIL = "email"


const instance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {'Content-Type': 'application/json'},
});

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            const status = error.response.status;
            if (status >= 500) {
                // Handle 5xx server errors
                console.error('Server Error:', error.response.data);
            }
            // pass others

        }
        else if (error.request) {
            // server couldn't response
            console.error('No response received:', error.request);
        }
        else {
            // could not make request
            console.error('Request Error:', error.message);
        }
        return Promise.reject(error);
    }
);


export const pretraziOglase = async (queryParams, currentPage, pageSize) => {
    if (!queryParams.toString()) {
        return pretraziIstaknute()
    }
    else {
        queryParams.set("page", currentPage)
        queryParams.set("size", pageSize)
        const response = await instance.get(`${API_VERSION}/${SEARCH}?${queryParams}`);
        return response.data;
    }

};

export const pretraziIstaknute = async () => {
    const response = await instance.get(`${API_VERSION}/${SEARCH}/${PROMOTED}`);
    return response.data;
};

// use lowercase kuca or stan here
export const dohvatiOglas = async (id, type) => {
    switch (type) {
        case KUCA.toLowerCase(): {
            return dohvatiKucu(type + "/" + id)
        }
        case STAN.toLowerCase(): {
            return dohvatiStan(type + "/" + id)
        }
        case POSLOVNI_PROSTOR.toLowerCase(): {
            return dohvatiPoslovniProstor(type + "/" + id)
        }
        case ZEMLJISTE.toLowerCase(): {
            return dohvatiZemljiste(type + "/" + id)
        }
    }
}

export const dohvatiKucu = async (id) => {
    const response = await instance.get(`${API_VERSION}/${HOUSES}/${id}`);
    return response.data;
};

export const dohvatiZemljiste = async (id) => {
    const response = await instance.get(`${API_VERSION}/${ZEMLJISTE_API}/${id}`);
    return response.data;
};

export const dohvatiPoslovniProstor = async (id) => {
    const response = await instance.get(`${API_VERSION}/${POSLOVNI}/${id}`);
    return response.data;
};

export const dohvatiStan = async (id) => {
    const response = await instance.get(`${API_VERSION}/${APARTMENTS}/${id}`);
    return response.data;
};

export const dohvatiGradskuListu = async () => {
    const response = await instance.get(`${API_VERSION}/${CITIES}`);
    return response.data;
};
export const dohvatiNeighbourhoodListu = async (id) => {
    const response = await instance.get(`${API_VERSION}/${CITIES}/${id}/neighbourhood`);
    return response.data;
};

export const prijaviKorisnika = async (username, password) => {
    const response = await instance.post(`${API_VERSION}/${AUTH}/login`, {
        username,
        password
    });

    const data = response.data;
    const token = response.headers.get("JWT_HEADER")
    return {
        data,
        token
    }
};


export const stvoriSlike = async (formData, oglas_id, token) => {
    const response = await instance.post(`${API_VERSION}/${IMAGE}/${oglas_id}`,
        formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': token
            }
        })
    return response.data;
};

export const obrisiSliku = async (oglas_id, image_id, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.delete(`${API_VERSION}/${IMAGE}/${oglas_id}/${image_id}`,
        {headers})
    return response.data;

};

export const stvoriKucu = async (formData, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.post(`${API_VERSION}/${HOUSES}`,
        formData, {headers});
    return response.data;
};

export const stvoriZemljiste = async (formData, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.post(`${API_VERSION}/${ZEMLJISTE_API}`,
        formData, {headers});
    return response.data;
};

export const stvoriPoslovni = async (formData, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.post(`${API_VERSION}/${POSLOVNI}`,
        formData, {headers});
    return response.data;
};

export const azurirajKucu = async (formData, listingId, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.put(`${API_VERSION}/${HOUSES}/${listingId}`,
        formData, {headers});
    return response.data;
};

export const azurirajZemljiste = async (formData, listingId, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.put(`${API_VERSION}/${ZEMLJISTE_API}/${listingId}`,
        formData, {headers});
    return response.data;
};

export const azurirajPoslovni = async (formData, listingId, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.put(`${API_VERSION}/${POSLOVNI}/${listingId}`,
        formData, {headers});
    return response.data;
};

export const stvoriStan = async (formData, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.post(`${API_VERSION}/${APARTMENTS}`,
        formData, {headers});
    return response.data;
};

export const azurirajStan = async (formData, listingId, token) => {
    const headers = token ? { Authorization: `${token}` } : undefined;
    const response = await instance.put(`${API_VERSION}/${APARTMENTS}/${listingId}`,
        formData, {headers});
    return response.data;
};

export const posaljiFormu = async (formData) => {
    const response = await instance.post(`${API_VERSION}/${EMAIL}`, formData);
    return response.data;
};


// todo: podkategorije se prikazuju za zemljiste i poslovni a ne bi smijelo
// todo: maknuti input box na home page (odmah da npr grad zagreb vodi dalje)
// todo: mobitel gumb na karticama koji se animira ne radi


export const updateUser = async (id, data, token) => {
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
    const response = await instance.put(`/users/${id}`, data, { headers });
    return response.data;
};


export default instance;
