import React from "react";
import "./ListingViewDesktop.css";
import {Icon} from "semantic-ui-react";
import Carousel from "../../carousel/Carousel";
import {STAROGRADNJA} from "../../../../mockData";
import {Link} from "react-router-dom";


const ListingViewDesktop = (props) => {
    const images = props.images;
    const listing = props.listing;

    const type = listing.additionalInfo.type;


    function generateIcons() {
        switch (type) {
            case "KUCA": {
                return (
                    <>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.livingArea} m² <Icon name="home"/>
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.landArea} m² <Icon name="expand"/>
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.bedrooms} <Icon name="bed" />
                            </p>
                        </div>

                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.bathrooms} <Icon name="bath" />
                            </p>
                        </div>
                    </>
            )
            }

            case "STAN": {
                return (
                    <>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.livingArea} m²
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>
                                Kat: {listing.additionalInfo.floor}
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.bedrooms} <Icon name="bed" />
                            </p>
                        </div>

                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.bathrooms}  <Icon name="bath" />
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.elevator ? <span>Dizalo: da</span> : <span>Dizalo: ne</span>}
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>{listing.additionalInfo.balcony ? <span>Balkon/terasa: da</span> :
                                <span>Balkon/terasa: ne</span>}</p>
                        </div>
                    </>
                )
            }

            case "ZEMLJISTE": {
                return (
                    <>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.landArea} m²
                            </p>
                        </div>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.permit ? "Građevinsko: da" : "Građevinsko: ne"}
                            </p>
                        </div>
                    </>

                )
            }

            case "POSLOVNI_PROSTOR": {
                return (
                    <>
                        <div className="oglas-text-icon">
                            <p>
                                {listing.additionalInfo.livingArea} m²
                            </p>
                        </div>
                    </>

                )
            }
            default: return null;
        }
    }

    function generateItem2() {

        if (listing.additionalInfo.construction) {
            return (
                <>
                    <p>Cijena po m2: </p>
                    <p>Kategorija: </p>
                    <p>Vrsta: </p>
                    {listing.additionalInfo.construction && <p>Gradnja: </p>}
                    {listing.additionalInfo.construction && <p>Godina gradnje: </p>}
                    {(listing.additionalInfo.construction.constructionType === STAROGRADNJA) &&
                        <p>Godina renovacije: </p>}
                </>
            )
        }
        return (
            <>
                <p>Cijena po m2: </p>
                <p>Kategorija: </p>
                <p>Vrsta: </p>
            </>
        )
    }

    function renderType() {
        switch (listing.additionalInfo.type) {
            case "KUCA": {
                switch (listing.additionalInfo.subType) {
                    case "VIKENDICA": return "Vikendica"
                    case "KUCA_ZA_ODMOR": return "Kuća za odmor"
                    case "VILLA": return "Villa"
                    default: return "Kuća"
                }
            }
            case "POSLOVNI_PROSTOR": {
                return "Poslovni prostor"
            }
            case "STAN": {
                switch (listing.additionalInfo.subType) {
                    case "APARTMAN": return "Apartman"
                    default: return "Stan"
                }
            }
            case "ZEMLJISTE": {
                return "Zemljište"
            }
            default: return null
        }
    }

    function generateItem3() {
        if (listing.additionalInfo.construction) {
            return (
                <>
                    <p>
                        {(
                            listing.price / listing.additionalInfo.livingArea
                        ).toFixed(2)}{" "}
                        €
                    </p>
                    <p>
                        {listing.category.charAt(0).toUpperCase() +
                            listing.category.slice(1).toLowerCase()}
                    </p>
                    <p>
                        {renderType()}
                    </p>

                    {listing.additionalInfo.construction &&
                        <p>
                            {listing.additionalInfo.construction.constructionType.charAt(0).toUpperCase() +
                                listing.additionalInfo.construction.constructionType.slice(1).toLowerCase()}
                        </p>
                    }

                    {listing.additionalInfo.construction &&
                        <p>
                            {listing.additionalInfo.construction.constructionYear}.
                        </p>
                    }

                    {(listing.additionalInfo.construction.constructionType === STAROGRADNJA) &&
                        <p>{listing.additionalInfo.construction.renovationYear}.</p>}
                </>
            )
        }

        return (
            <>
                <p>
                    {(
                        listing.price / listing.additionalInfo.landArea
                    ).toFixed(2)}{" "}
                    €
                </p>
                <p>
                    {listing.category.charAt(0).toUpperCase() +
                        listing.category.slice(1).toLowerCase()}
                </p>
                <p>
                    {renderType()}
                </p>
            </>
        )


    }

    const createGridItems = (array) => {
        return array.map((item, index) => <p key={index}>{item}</p>);
    };

    return (
        <>
            <Carousel images={images} />
            <div className="details-container">
                <div className="main-info">
                    <div className="oglas-naslov-adresa">
                        <h2 className="oglas-naslov-oglasa">{listing.title}</h2>
                        <p className="oglas-adresa-oglasa"><Icon name={"location arrow"}/>{`${listing.location.city}, ${listing.location.neighbourhood}`}</p>
                    </div>

                    <div className="oglas-text-icon-container">
                        {generateIcons()}
                    </div>

                    <div className="oglas-opsirnije">
                        <h3>Opširnije</h3>
                        <div
                            dangerouslySetInnerHTML={{ __html: listing.description }}
                        ></div>
                    </div>
                    <div className="oglas-dodatno">
                        <h3>Dodatno</h3>
                        <div className="grid-container-dodatne-info">
                            {createGridItems(listing.userAddedAdditionalInfo)}
                        </div>
                    </div>
                </div>

                <div className="oglas-key-info-grid-container">
                    <div className="oglas-key-info-grid-item" id="item1">
                        <h3>{listing.price} €</h3>
                    </div>
                    <div className="oglas-key-info-grid-item" id="item2">
                        {generateItem2()}
                    </div>
                    <div className="oglas-key-info-grid-item" id="item3">
                        {generateItem3()}
                    </div>
                    <div id="item4">
                        <Link to={"/kontakt?id=" + listing.id}>
                            <button className="detail-oglas-button oglas-kontakt-button">
                                Kontaktirajte nas za ovu nekretninu
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListingViewDesktop;
