import {useMediaQuery} from "react-responsive";
import ListingViewDesktop from "./desktop/ListingViewDesktop";
import ListingViewMobile from "./mobile/ListingViewMobile";

const ListingView = ({listing, images}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 940px)' });

    return (
        <>
            {isMobile ?  <ListingViewMobile images={images} listing={listing}/> : <ListingViewDesktop images={images} listing={listing}/> }
        </>
    );
};

export default ListingView;
