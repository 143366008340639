import React, {useContext, useEffect, useState} from 'react';
import {Form, Button, Checkbox, Input, Select, Icon} from 'semantic-ui-react';
import {
    dohvatiGradskuListu,
    dohvatiNeighbourhoodListu, dohvatiZemljiste, azurirajZemljiste, stvoriZemljiste
} from "../../api";
import ImageUpload from "../real-estate-listing-image-upload/ImageUpload";
import {categoryOptions} from "../../mockData";
import authContext from "../../auth/auth-context";


const CreateZemljiste = ({setId, workMode = "create", listingId}) => {

    const context = useContext(authContext)

    const [cities, setCities] = useState([])
    const [neighs, setNeighs] = useState([])



    useEffect(() => {
        if (workMode === 'update') {
            dohvatiZemljiste(listingId).then(r => {
                setAdData(r)
            })
        }

        let uniqueNeighs = new Set();

        dohvatiGradskuListu().then(r => {
            setCities(r);
            r.forEach(grad => {
                dohvatiNeighbourhoodListu(grad.value).then(res => {
                    res.forEach(neigh => uniqueNeighs.add(neigh));
                    setNeighs([...uniqueNeighs]);
                });
            });
        });
    }, []);




    const [adData, setAdData] = useState({
        title: '',
        additionalInfo: {
            type: 'ZEMLJISTE',
            landArea: '',
            permit: false
        },
        price: '',
        location: {
            city: '',
            neighbourhood: ''
        },
        category: '',
        promoted: false,
        userAddedAdditionalInfo: [],
        description: ''
    });

    const handleChange = (e, data) => {
        const { name, value } = data;

        if (name === 'city' || name === 'neighbourhood') {
            setAdData(prevAdData => ({
                ...prevAdData,
                location: { ...prevAdData.location, [name]: value }
            }));
        } else if (['landArea', 'type'].includes(name)) {
            setAdData(prevAdData => ({
                ...prevAdData,
                additionalInfo: { ...prevAdData.additionalInfo, [name]: value }
            }));
        }
        else if (name === 'userAddedInfo') {
            // Pretpostavimo da korisnik unosi svaku dodatnu informaciju kao zaseban redak u TextArea
            const infoArray = value.split('\n'); // Razdvaja unos po redovima
            setAdData(prevAdData => ({
                ...prevAdData,
                userAddedAdditionalInfo: infoArray
            }));
        }

        else {
            setAdData(prevAdData => ({ ...prevAdData, [name]: value }));
        }
    };



    const handleSubmit = (event) => {
        event.preventDefault();

        if (workMode === 'create') {
            stvoriZemljiste(adData, context.token).then(r => setId(r));
        } else if (workMode === 'update') {
            azurirajZemljiste(adData, listingId, context.token).then(r => setId(r));
        }
    };


    return (
        <>
            <Form style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "50px"
            }}>
                <Form.Field>
                    <label>Naslov</label>
                    <Input name="title" placeholder='Naslov' onChange={handleChange} value={adData.title}/>
                </Form.Field>
                <Form.Field>
                    <label>Cijena</label>
                    <Input name="price" placeholder='Cijena' type='number' onChange={handleChange} value={adData.price} />
                </Form.Field>
                <Form.Field>
                    <label>Grad</label>
                    <Form.Dropdown
                        name="city"
                        placeholder='Gradovi'
                        fluid
                        selection
                        options={cities || []}
                        search
                        onChange={handleChange}
                        icon='search'
                        value={adData.location.city || ""}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Četvrt</label>

                    <Form.Dropdown
                        name="neighbourhood"
                        placeholder='Naselja'
                        fluid
                        selection
                        options={neighs || []}
                        search
                        onChange={handleChange}
                        disabled={cities.length === 0}
                        value={adData.location.neighbourhood || ""}
                        icon='search'
                    />
                </Form.Field>
                <Form.Field>
                    <label>Kategorija</label>
                    <Select name="category" options={categoryOptions} placeholder='Izaberite kategoriju' onChange={handleChange}
                    value={adData.category}/>
                </Form.Field>

                <Form.Group widths='equal'>
                    <Form.Input fluid label='Površina zemljišta' name='landArea'
                                placeholder='Površina zemljišta (m²)' type='number' onChange={handleChange}  value={adData.additionalInfo.landArea} />
                </Form.Group>

                <Form.Field>
                    <Checkbox
                        label='Istaknuti oglas?'
                        name="promoted"
                        checked={adData.promoted}
                        onChange={(e, { checked }) => setAdData({ ...adData, promoted: checked })}
                    />
                    <Checkbox
                        label='Dozvola'
                        name="permit"
                        checked={adData.additionalInfo.permit}
                        onChange={(e, { checked }) => {
                            setAdData(prevAdData => ({
                                ...prevAdData,
                                additionalInfo: {
                                    ...prevAdData.additionalInfo,
                                    permit: checked
                                }
                            }));
                        }}

                    />
                </Form.Field>

                <Form.TextArea
                    label='Dodatne informacije'
                    name="userAddedInfo"
                    placeholder='Unesite dodatne informacije...'
                    onChange={handleChange}
                    value={adData.userAddedAdditionalInfo.join('\n')}
                />



                <Form.TextArea
                    label='Opis'
                    name="description"
                    placeholder='Opis nekretnine...'
                    onChange={handleChange}
                    value={adData.description}
                />
                <Button animated={"fade"}   onClick={handleSubmit} color="green" >
                    <Button.Content visible> {workMode === 'create' ? 'Kreiraj Oglas' : 'Ažuriraj Oglas'}</Button.Content>
                    <Button.Content hidden><Icon name='right arrow' /></Button.Content>
                </Button>
            </Form>

            {adData.id && <ImageUpload oglas_id={adData.id}/>}

        </>
    );
};

export default CreateZemljiste;
