import React, {useEffect, useState} from 'react';
import {Button, Form, Grid, Input, Message, Segment, Container, Header, Divider} from 'semantic-ui-react';
import NavigationComponent from "../components/navigation/NavigationComponent";
import FooterComponent from "../components/footer/FooterComponent";
import {posaljiFormu} from "../api";
import {useMediaQuery} from "react-responsive";
import {useLocation} from "react-router-dom";

const ContactPage = () => {

    const [initialMessage, setInitialMessage] = useState("")
    const location = useLocation()


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Kontaktirajte nas - Gorica Nekretnine";
        let id = new URLSearchParams(location.search).get("id");
        setInitialMessage(id)
    }, []);

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formState, setFormState] = useState({});

    const isMobile = useMediaQuery({ maxWidth: 769 });


    const containerStyle = !isMobile ? {height: "70vh", display: 'flex', flexDirection: 'column', justifyContent: 'center'}
        : {}

    const segmentStyle = !isMobile ? { padding: '2em',
            backgroundColor: '#272d2b', height: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }
        : {
            backgroundColor: '#272d2b'
        }

    const gridColumnStyle = !isMobile ? {height: "100%"}
        : {}


    function handleChange(e, { name, value, checked }) {
        const newValue = typeof checked === 'boolean' ? checked : value;
        setFormState(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    }

    function handleSubmit() {
        setIsLoading(true);

        posaljiFormu(formState)
            .then(r => {
                console.log(r)
                setIsLoading(false);
                setIsSuccess(true);
                setIsError(false)
            })
            .catch(e => {
                console.log(e)
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(true)
            })


    }



    return (
        <>
            <NavigationComponent currentPage={"kontakt"} />

            <Container style={containerStyle}>
                <h2 style={{textAlign: "center", fontSize: "40px"}}>Gorica Nekretnine</h2>
                <Grid padded stackable>
                    <Grid.Row>

                        <Grid.Column width={6} style={gridColumnStyle}>
                            <Segment inverted style={segmentStyle}>
                                <div style={{ color: 'white' }}>
                                    <p style={{ marginBottom: '1em', fontSize: "18px" }}>
                                        Slobodno nas kontaktirajte u bilo koje vrijeme. Odgovorit ćemo vam čim budemo mogli!
                                    </p>
                                    <Divider style={{backgroundColor: "rgba(248, 207, 97, 0.7)"}} />
                                    <a className="footer-link" href="mailto:info@gorica-nekretnine.hr">info@gorica-nekretnine.hr</a>
                                    <a className="footer-link" href="tel:+385995550110">+385 99 555 0110</a>
                                    <a className="footer-link" href="tel:+385915351146">+385 91 535 1146</a>
                                    <a className="footer-link" href="tel:+385995550106">+385 99 555 0106</a>
                                    <p>Lokacija: Trg Kralja Tomislava 41, 10410 V. Gorica</p>
                                </div>
                            </Segment>
                        </Grid.Column>


                        <Grid.Column width={10} style={{ paddingRight: '3em' }}>
                            <Segment raised padded style={{ backgroundColor: 'white' }}>
                                <Header as='h1'>Pošaljite nam poruku!</Header>
                                    <Form
                                        success={isSuccess}
                                        error={isError}
                                        loading={isLoading}
                                    >
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                required
                                                fluid
                                                label='Ime'
                                                placeholder='Ime'
                                                name='firstName'
                                                onChange={handleChange}
                                            />
                                            <Form.Input
                                                required
                                                fluid
                                                label='Prezime'
                                                placeholder='Prezime'
                                                name='lastName'
                                                onChange={handleChange}
                                            />
                                        </Form.Group>


                                        <Form.Field required>
                                            <label>Email</label>
                                            <Input
                                                icon="at"
                                                iconPosition="left"
                                                placeholder="email@domena.com"
                                                name="email"
                                                onChange={handleChange}
                                            />
                                        </Form.Field>

                                        {initialMessage &&
                                        <Form.Field>
                                            <label>Nekretnina</label>
                                            <Input value={initialMessage}>

                                            </Input>
                                        </Form.Field>
                                        }

                                        <Form.TextArea
                                            required
                                            label='Upit'
                                            placeholder='Poruka koja objašnjava Vaš problem'
                                            name='about'
                                            onChange={handleChange}

                                        />
                                        <Form.Checkbox
                                            required
                                            label='Prihvaćam politiku privatnosti'
                                            name='terms'
                                            onChange={handleChange}
                                        />
                                        {isSuccess && (
                                            <Message
                                                success
                                                header='Uspješno ste poslali upit'
                                                content="Obratiti ćemo vam se u najkraćem mogućem roku"
                                            />
                                        )}

                                        {isError && (
                                            <Message
                                                error
                                                header='Došlo je do greške'
                                                content="Pokušajte ponovno"
                                            />
                                        )}
                                        <Button color="green" disabled={!formState.terms} onClick={handleSubmit}>Pošalji</Button>

                                    </Form>
                            </Segment>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Container>

            <FooterComponent />
        </>
    );
};

export default ContactPage;
