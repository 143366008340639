import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../auth/auth-context";
import NavigationComponent from "../components/navigation/NavigationComponent";
import FooterComponent from "../components/footer/FooterComponent";
import {Button, Card, Icon, Message} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";

const ProfilePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Moj profil - Gorica Nekretnine";
    }, []);


    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    function odjava() {
        setIsLoggingOut(true);
        setTimeout(() => {
            authContext.logout();
            navigate("/");
        }, 1500);
    }



    return (
        <>
            <NavigationComponent currentPage={"kontrolna-ploca"}/>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                opacity: isLoggingOut ? 0 : 1,
                transition: 'opacity 1.5s ease-in-out'
            }}>
                {isLoggingOut &&
                    <Message icon style={{
                        width: "50%",
                        fontSize: "20px",
                        transition: 'opacity 1.5s ease-in-out'
                    }}>
                        <Icon name='circle notched' loading />
                        <Message.Content>
                            <Message.Header>Doviđenja</Message.Header>
                            Odjavljujemo vas iz sustava.
                        </Message.Content>
                    </Message>
                }
                <Card style={{
                    width: "50%"
                }}>
                    <Card.Content>
                        <Card.Header>{authContext.username}</Card.Header>
                        <Card.Meta>
                            {authContext.roles.split("_")[1].toLowerCase()}
                        </Card.Meta>

                    </Card.Content>
                    <Card.Content extra>
                        <div className='ui two buttons'>
                            <Button animated='fade' color='green' onClick={() => {
                                navigate("/novi_oglas")
                            }}>
                                <Button.Content visible>Dodaj novi oglas</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='arrow up' />
                                </Button.Content>
                            </Button>
                            <Button animated='fade' color='red' onClick={odjava}>
                                <Button.Content visible>Odjavi se</Button.Content>
                                <Button.Content hidden>
                                    <Icon name='log out' />
                                </Button.Content>
                            </Button>
                        </div>
                    </Card.Content>
                </Card>
            </div>
            <FooterComponent/>
        </>
    );
};

export default ProfilePage;
