import React from 'react';
import './DividerComponent.css';

function DividerComponent({ color, opacity = 0.5, marginTop = 10, marginBottom = 10 }) {
    const hexToRgbA = (hex) => {
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c = hex.substring(1).split('');
            if(c.length === 3){
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return `rgba(${(c>>16)&255}, ${(c>>8)&255}, ${c&255}, ${opacity})`;
        }
        throw new Error('Bad Hex');
    };

    const style = {
        backgroundColor: hexToRgbA(color),
        marginTop: marginTop,
        marginBottom: marginBottom
    };

    return <div className="divider" style={style}></div>;
}

export default DividerComponent;
