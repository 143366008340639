import {Button, Icon, Card, Image, Message} from "semantic-ui-react";
import { baseURL, dohvatiOglas, obrisiSliku, stvoriSlike } from "../../api";
import React, {useContext, useEffect, useState} from "react";
import authContext from "../../auth/auth-context";

const ImageUpload = ({ oglas_id }) => {

    const context = useContext(authContext)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [dohvaceneSlike, setDohvaceneSlike] = useState([]);
    const [isLoading, setIsLoading] = useState(false)


    function azurirajSlike() {
        setIsLoading(true)
        dohvatiOglas(oglas_id.split("/")[1], oglas_id.split("/")[0])
            .then(r => {
                setDohvaceneSlike(r.images)
                setIsLoading(false)
            })
            .catch(e => {
                setIsLoading(false)
                console.error('Greška prilikom dohvaćanja oglasa:', e)
            });
    }

    useEffect(() => {
            azurirajSlike()
    }, []);

    function onDelete(imageId) {
        obrisiSliku(oglas_id, imageId, context.token)
            .then(r => {
                azurirajSlike()
            })
            .catch(e => console.error('Greška prilikom brisanja slike:', e));
    }

    const handleFileChange = (event) => {
        setSelectedFiles(Array.from(event.target.files));
    };

    const handleUpload = () => {
        if (selectedFiles.length > 0) {
            const formData = new FormData();
            selectedFiles.forEach(file => {
                formData.append('slike', file);
            });
            setSelectedFiles([])
            setIsLoading(true)
            stvoriSlike(formData, oglas_id, context.token)
                .then(r => {
                    azurirajSlike(context.token)
                    setIsLoading(false);
                })
                .catch(e => {
                    setIsLoading(false);
                    console.error('Greška prilikom stvaranja slika:', e)
                });
        }
    };


    return (
        <div style={{height: "100vh"}}>
            {(!isLoading && dohvaceneSlike.length === 0) && <Message icon color={"yellow"} style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                transition: 'opacity 1.5s ease-in-out',
                marginBottom: '50px'
            }}>
                <Icon name='info'/>
                <Message.Content>
                    <Message.Header>
                        Ova nekretnina trenutno nema slika
                    </Message.Header>
                    Odaberite slike i pritisnite gumb
                </Message.Content>
            </Message>}
            <div style={{ width: "50%", marginLeft: "auto", marginRight: "auto", marginBottom: "50px" }}>
                <label><b>Prijenos slika</b></label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    multiple
                />

                <Button
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                    positive
                    onClick={handleUpload}
                    icon labelPosition='right'
                    disabled={selectedFiles.length === 0}>
                    Prenesi slike
                    <Icon name='up arrow' />
                </Button>
            </div>

            <div style={{ width: "75%", marginLeft: "auto", marginRight: "auto", marginBottom: "50px" }}>
                <Card.Group itemsPerRow={4}>
                    {!isLoading && dohvaceneSlike.map((item, i) => (
                        <Card key={item}>
                            <Image src={`${baseURL}/api/v1/image/${item}`} wrapped ui={false} />
                            <Card.Content textAlign="center">
                                <Button
                                    color="red"
                                    onClick={() => onDelete(item)}
                                >
                                    Obriši
                                </Button>
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            </div>
        </div>
    );
};

export default ImageUpload;
