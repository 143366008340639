import {Button, Form, Input, Message} from "semantic-ui-react";
import NavigationComponent from "../components/navigation/NavigationComponent";
import React, {useContext, useEffect, useState} from "react";
import FooterComponent from "../components/footer/FooterComponent";
import {prijaviKorisnika} from "../api";
import AuthContext from "../auth/auth-context";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Prijavi se - Gorica Nekretnine";
    }, []);


    const authContext = useContext(AuthContext);

    // Definiranje stanja za email i lozinku
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [loginErr, setLoginErr] = useState()
    const navigate = useNavigate();


    function prijava() {
        setLoading(true)
        prijaviKorisnika(email, password).then(data => {
            setLoginErr()
            setLoading(false)
            authContext.login(data.token)
            navigate('/profile');
        }).catch(err => {
            setLoading(false);
            setLoginErr(err)
        });
    }
    return (
        <>
            <NavigationComponent currentPage={"login"}></NavigationComponent>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '70vh',
                width: '100%',
            }}>
                <Form error onSubmit={prijava} loading={loading} style={{
                    maxWidth: '500px',
                    width: '100%',
                    fontSize: '1.2em'
                }}>
                    <h2 style={{ textAlign: 'center' }}>Dobrodošli</h2>
                    <Form.Field>
                        <label>Email</label>
                        <Input
                            icon="at"
                            iconPosition="left"
                            placeholder="email@domena.com"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Lozinka</label>
                        <Input
                            icon="user secret"
                            iconPosition="left"
                            placeholder="Lozinka"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Form.Field>

                    {loginErr &&  <Message
                        error
                        header='Pogrešan email ili lozinka'
                        content='Pokušajte ponovno.'/>
                    }

                    <Form.Field style={{ textAlign: 'center' }}>
                        <Button type='submit' style={{width: '150px', fontSize: '1em'}}>Prijavi se</Button>
                    </Form.Field>
                </Form>
            </div>
            <FooterComponent/>
        </>
    );
};

export default LoginPage;
