import { Link } from "react-router-dom";
import "./NavigationMobile.css";
import AuthContext from "../../../auth/auth-context";
import { useContext, useEffect, useRef, useState } from "react";
import { Twirl as Hamburger } from 'hamburger-react';
import { Icon } from "semantic-ui-react";

const cursorStyle = {
    cursor: "pointer",
};

const NavigationMobile = ({ currentPage }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isSubNavOpen, setIsSubNavOpen] = useState(false);
    const [isRentSubNavOpen, setIsRentSubNavOpen] = useState(false);
    const menuContainer = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuContainer.current && !menuContainer.current.contains(event.target)) {
                setIsNavOpen(false);
                setIsSubNavOpen(false);
                setIsRentSubNavOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="menu-container" ref={menuContainer}>
            <div className="item-left">
                <Link to="/">
                    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="My Description" className="nav-mob-logo-img" />
                </Link>
            </div>
            <div className="item-right">
                <Hamburger toggled={isNavOpen} toggle={setIsNavOpen} />
            </div>

            <div className={`dropdown-content ${isNavOpen ? 'show' : ''}`}>
                {!isSubNavOpen && !isRentSubNavOpen ? (
                    <div className="menu-items-wrapper">
                        <Link to={"/"} style={cursorStyle} >
                            <span className="nav-mob-menu-item">
                                POČETNA
                            </span>
                        </Link>
                        <div style={cursorStyle} onClick={() => setIsSubNavOpen(true)}>
                            <span className="nav-mob-menu-item">
                                PRODAJA <Icon name={"arrow down"} size={"small"} />
                            </span>
                        </div>
                        <div style={cursorStyle} onClick={() => setIsRentSubNavOpen(true)}>
                            <span className="nav-mob-menu-item">
                                NAJAM <Icon name={"arrow down"} size={"small"} />
                            </span>
                        </div>
                        <Link to={"/kontakt"} style={cursorStyle} >
                            <span className="nav-mob-menu-item">
                                KONTAKT
                            </span>
                        </Link>
                        <Link to={"/cesto-postavljena-pitanja"} style={cursorStyle} >
                            <span className="nav-mob-menu-item">
                                FAQ
                            </span>
                        </Link>

                        <Link to={"/prijava"} style={cursorStyle}  >
                            <span className="nav-mob-menu-item">
                                PRIJAVA
                            </span>
                        </Link>
                    </div>
                ) : null}

                {isSubNavOpen && (
                    <>
                        <div style={{ ...cursorStyle, marginBottom: "50px", textAlign: "center" }} onClick={() => setIsSubNavOpen(false)}>
                            <span className={"nav-mob-back-button"}><Icon name={"arrow left"} size={"large"} /></span>
                            <br />
                            <span className={"nav-mob-back-button"}>PRODAJA</span>
                        </div>
                        <div className="dropdown-links">
                            <span className={"nav-mob-back-button"}>KUĆA</span>
                            <ul>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Centar</Link></li>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Sve</Link></li>
                                <li><Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Velika Gorica</Link></li>
                                <li><Link to="/pretraga?gradovi=Dalmacija&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Dalmacija</Link></li>
                                <li><Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=KUCA&kategorije=PRODAJA#rezultati-pretrage-id">Istra i Kvarner</Link></li>

                            </ul>
                            <span className={"nav-mob-back-button"}>STAN</span>
                            <ul>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Centar</Link></li>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Zagreb - Sve</Link></li>
                                <li><Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Velika Gorica</Link></li>
                                <li><Link to="/pretraga?gradovi=Dalmacija&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Dalmacija</Link></li>
                                <li><Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=STAN&kategorije=PRODAJA#rezultati-pretrage-id">Istra i Kvarner</Link></li>

                            </ul>
                        </div>
                    </>
                )}

                {isRentSubNavOpen && (
                    <>
                        <div style={{ ...cursorStyle, marginBottom: "50px", textAlign: "center"}} onClick={() => setIsRentSubNavOpen(false)}>
                            <span className={"nav-mob-back-button"}><Icon name={"arrow left"} size={"large"} /></span>
                            <br />
                            <span className={"nav-mob-back-button"}>NAJAM</span>
                        </div>
                        <div>
                            <span className={"nav-mob-back-button"}>KUĆA</span>
                            <ul>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Centar</Link></li>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Sve</Link></li>
                                <li><Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Velika Gorica</Link></li>
                                <li><Link to="/pretraga?gradovi=Dalmacija&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Dalmacija</Link></li>
                                <li><Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=KUCA&kategorije=NAJAM#rezultati-pretrage-id">Istra i Kvarner</Link></li>
                            </ul>
                            <span className={"nav-mob-back-button"}>STAN</span>
                            <ul>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&naselja=Gornji+Grad-Medveščak&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Centar</Link></li>
                                <li><Link to="/pretraga?gradovi=Grad+Zagreb&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Zagreb - Sve</Link></li>
                                <li><Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Velika Gorica</Link></li>
                                <li><Link to="/pretraga?gradovi=Dalmacija&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Dalmacija</Link></li>
                                <li><Link to="/pretraga?gradovi=Istra+i+Kvarner&tipovi=STAN&kategorije=NAJAM#rezultati-pretrage-id">Istra i Kvarner</Link></li>

                            </ul>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default NavigationMobile;
