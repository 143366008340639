import React from 'react';
import "./SliderComponentDesktop.css"
import {Button, Card, Icon, Image} from "semantic-ui-react";
import {useMediaQuery} from "react-responsive";
import {Link} from "react-router-dom";

function SliderComponentDesktop () {

    const isMobile = useMediaQuery({ maxWidth: 929 });

    const itemsPerRowLocation = isMobile ? 1 : 3


    const cardStyleLocation = isMobile ? {
    } : {
        maxWidth: "90%",
        marginLeft: "auto",
        marginRight: "auto",
    }

    const divStyleLocation = isMobile ? {
        maxWidth: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "50px"
    } : { height: "550px", marginBottom: "65px"}


    return (
        <>
            <div style={divStyleLocation}>
                <h2 style={{textAlign: "center", marginBottom: "30px", marginTop: "45px"}}>Pretražite po lokaciji</h2>
                <Card.Group itemsPerRow={itemsPerRowLocation} style={cardStyleLocation}>
                    <Card>
                        <Image src={`${process.env.PUBLIC_URL}/zagreb.png`}  wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Zagreb</Card.Header>
                            <Card.Description>
                                Zagreb, glavni grad Hrvatske,
                                je dinamičan spoj povijesne ljepote i suvremenog života.
                                Svojim slikovitim starim gradom s uskim uličicama, katedralom i
                                tržnicama, pruža šarmantnu atmosferu bogatu kulturnim naslijeđem.
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{textAlign: "center"}}>

                            <Link to="/pretraga?gradovi=Grad+Zagreb#rezultati-pretrage-id">
                                <Button animated='fade'  style={{backgroundColor: "#f8cf61", color: "#272d2b"}}>
                                        <Button.Content visible>Pretraži Zagreb</Button.Content>
                                    {!isMobile && <Button.Content hidden><Icon name={"arrow right"}/></Button.Content>}
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card>


                    <Card>
                        <Image src={`${process.env.PUBLIC_URL}/split.png`} wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Split</Card.Header>
                            <Card.Description>
                                Split, drugi po veličini grad u Hrvatskoj, očarava svojim jedinstvenim spojem antičke povijesti i
                                mediteranskog šarma.
                                Predivne plaže,
                                sunčana klima i kulturna raznolikost čine Split privlačnim odredištem za posjetitelje i omiljenim mjestom stanovnika.

                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{textAlign: "center"}}>
                            <Link to="/pretraga?gradovi=Dalmacija&naselja=Split#rezultati-pretrage-id">
                                <Button animated='fade'  style={{backgroundColor: "#f8cf61", color: "#272d2b"}}>
                                    <Button.Content visible>Pretraži Split</Button.Content>
                                    {!isMobile && <Button.Content hidden><Icon name={"arrow right"}/></Button.Content>}
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card>


                    <Card>
                        <Image src={`${process.env.PUBLIC_URL}/gorica.jpg`} wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Velika Gorica</Card.Header>
                            <Card.Description>
                                Velika Gorica je grad u sjeverozapadnoj Hrvatskoj, pripada Zagrebačkoj županiji,
                                te je u županiji najveći grad po veličini i broju stanovništva.
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{textAlign: "center"}}>
                            <Link to="/pretraga?gradovi=Zagrebačka+županija&naselja=Velika+Gorica#rezultati-pretrage-id">
                                <Button animated='fade'  style={{backgroundColor: "#f8cf61", color: "#272d2b"}}>
                                    <Button.Content visible>Pretraži Veliku Goricu</Button.Content>
                                    {!isMobile && <Button.Content hidden><Icon name={"arrow right"}/></Button.Content>}
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </div>


            <div style={divStyleLocation}>
                <h2 style={{textAlign: "center", marginBottom: "30px"}}>Pretražite po vrsti nekretnine</h2>
                <Card.Group itemsPerRow={itemsPerRowLocation} style={cardStyleLocation}>
                    <Card>
                        <Image src={`${process.env.PUBLIC_URL}/villa.jpg`}  wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Villa</Card.Header>
                            <Card.Description>
                                Zanima Vas luksuz? Pronađite prekrasne ville.
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{textAlign: "center"}}>

                            <Link to="/pretraga?tipovi=KUCA&podTipovi=VILLA#rezultati-pretrage-id">
                                <Button animated='fade'  style={{backgroundColor: "#f8cf61", color: "#272d2b"}}>
                                    <Button.Content visible>Pretraži Ville</Button.Content>
                                    {!isMobile && <Button.Content hidden><Icon name={"arrow right"}/></Button.Content>}
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card>


                    <Card>
                        <Image src={`${process.env.PUBLIC_URL}/apartment.jpg`} wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Apartmani</Card.Header>
                            <Card.Description>
                                Tražite apartmane s pogledom na more? Na pravom ste mjestu.
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{textAlign: "center"}}>
                            <Link to="/pretraga?tipovi=STAN&podTipovi=APARTMAN#rezultati-pretrage-id">
                                <Button animated='fade'  style={{backgroundColor: "#f8cf61", color: "#272d2b"}}>
                                    <Button.Content visible>Pretraži Apartmane</Button.Content>
                                    {!isMobile && <Button.Content hidden><Icon name={"arrow right"}/></Button.Content>}
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card>


                    <Card>
                        <Image src={`${process.env.PUBLIC_URL}/vikendica.jpg`} wrapped ui={false} />
                        <Card.Content>
                            <Card.Header>Vikendice</Card.Header>
                            <Card.Description>
                                Bijeg za vikend? Pogledajte ponudu vikendica.
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra style={{textAlign: "center"}}>
                            <Link to="/pretraga?tipovi=KUCA&podTipovi=VIKENDICA#rezultati-pretrage-id">
                                <Button animated='fade'  style={{backgroundColor: "#f8cf61", color: "#272d2b"}}>
                                    <Button.Content visible>Pretraži Vikendice</Button.Content>
                                    {!isMobile && <Button.Content hidden><Icon name={"arrow right"}/></Button.Content>}
                                </Button>
                            </Link>
                        </Card.Content>
                    </Card>
                </Card.Group>
            </div>
        </>
    );
}

export default SliderComponentDesktop;
