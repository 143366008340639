import "./SearchComponentDesktop.css";
import React, {useRef, useState, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom"
import {Icon, Input} from "semantic-ui-react";
import {dohvatiGradskuListu, dohvatiNeighbourhoodListu} from "../../../api";
import {Form} from 'semantic-ui-react'
import {
    kategorijaList,
    kucaSubTypeList,
    kvadraturaSortList,
    priceSortList,
    stanSubTypeList,
    typesList
} from "../../../mockData";




function getSubTypeList(type) {
    // problem arr u arr
    let arr = []
    type.forEach(item => {
        if (item === "KUCA") arr.push(kucaSubTypeList);
        else if (item === "STAN") arr.push(stanSubTypeList);
    })
    return arr.flat();
}

const emptySearch = {
    cijenaMin: '',
    cijenaMax: '',
    cijenaPoredak: null,
    kvadraturaPoredak: null,
    gradovi: [],
    naselja: [],
    tipovi: [],
    podTipovi: [],
    kategorije: [],
    sobe: null,
    kupaonice: null,
    kvadraturaMin: '',
    kvadraturaMax: '',
    floor: null,
    balcony: null,
    elevator: null,
    permit: null,
}


const SearchComponentDesktop = ({onInitiateScroll, searchParams}) => {

    const navigate = useNavigate();
    const location = useLocation();


    const sidebarRef = useRef(null);


    useEffect(() => {
        function handleClickOutside(event) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setExpanded(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    // advanced search expansion
    const [expanded, setExpanded] = useState(false);

    const [state, setState] = useState(emptySearch);

    const [subTypesDropdown, setSubTypesDropdown] = useState([]);

    useEffect(() => {
        setSubTypesDropdown(getSubTypeList(state.tipovi));
    }, [state.tipovi])

    useEffect(() => {
        // Create a new URLSearchParams object from the current URL
        const urlParams = searchParams;
        const stateUpdates = {};

        Object.keys(emptySearch).forEach(key => {
            if (Array.isArray(emptySearch[key])) {
                // For array fields, use getAll to retrieve all values
                const values = urlParams.getAll(key);
                if (values.length > 0) {
                    stateUpdates[key] = values;
                }
            } else {
                // For non-array fields
                if (urlParams.has(key)) {
                    const value = urlParams.get(key);

                    // Handle boolean and number types
                    if (value === 'true') {
                        stateUpdates[key] = true;
                    } else if (value === 'false') {
                        stateUpdates[key] = false;
                    } else if (!isNaN(value) && value !== '') {
                        // Convert to number if it's a numeric string
                        stateUpdates[key] = Number(value);
                    } else {
                        stateUpdates[key] = value;
                    }
                }
            }
        });

        setState(prevState => ({
            ...prevState,
            ...stateUpdates
        }));
    }, [searchParams]);



    const updateField = (field, value) => {
        setState(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleChange = (e, field) => {
        updateField(field, e.target.value);
    };

    const scrollToTarget = onInitiateScroll;

    const [gradoviOptions, setGradoviOptions] = useState([]);
    const [naseljaOptions, setNaseljaOptions] = useState(new Map());

    const fetchGradoviAndNaselja = async () => {
        try {
            const gradovi = await dohvatiGradskuListu();
            setGradoviOptions(gradovi);
            const naseljaMap = new Map();
            for (const grad of gradovi) {
                const naselja = await dohvatiNeighbourhoodListu(grad.value);
                naseljaMap.set(grad.value, naselja);
            }
            setNaseljaOptions(naseljaMap);
        } catch (error) {
            console.error("Error fetching gradovi and naselja:", error);
        }
    };

    useEffect(() => {
        fetchGradoviAndNaselja();
    }, []);


    const [filteredNaseljaOptions, setfilteredNaseljaOptions] = useState([])


    useEffect(() => {
        let options = [];
        let grads = state.gradovi

        if (grads && grads.length > 0) {
            grads.forEach(grad => {
                const naselja = naseljaOptions.get(grad);
                if (naselja) {
                    options = options.concat(naselja.map(naselje => ({
                        key: naselje.value,
                        text: naselje.text,
                        value: naselje.value
                    })));
                }
            });
        }
        setfilteredNaseljaOptions(options)

    }, [naseljaOptions, state.gradovi]);



    const handleSubmit = (event) => {

        if (event) {
            event.preventDefault();
        }

        let params = new URLSearchParams();
        Object.keys(state).forEach(key => {
            if (state[key] || state[key] === 0) {
                if (Array.isArray(state[key])) {
                    state[key].forEach(value => {
                        if (value || value === 0) {
                            params.append(key, value);
                        }
                    });
                } else {
                    params.append(key, state[key]);
                }
            }
        });

        navigate({
            pathname: location.pathname,
            search: `?${params.toString()}`
        });


        scrollToTarget()
    };


    return (

        <div>

            <div className="search-form">
                <h2 className="expandable-title">Pretražite nekretnine</h2>

                <Form>

                    <Form.Group style={{display: 'flex', justifyContent: 'center'}} widths={"equal"}>
                        <Form.Dropdown
                            placeholder='Lokacija'
                            fluid
                            multiple
                            selection
                            options={gradoviOptions}
                            search
                            onChange={(e, { value }) => updateField('gradovi', value)}
                            value={state.gradovi}
                            icon='search'
                        />

                        <Form.Dropdown
                            placeholder='Podlokacija'
                            fluid
                            multiple
                            selection
                            options={filteredNaseljaOptions}
                            search
                            onChange={(e, { value }) => updateField('naselja', value)}
                            value={state.naselja}
                            disabled={state.gradovi.length === 0}
                            icon='search'
                        />

                        <Form.Dropdown
                            placeholder='Tip'
                            fluid
                            multiple
                            selection
                            options={typesList}
                            onChange={(e, { value }) => updateField('tipovi', value)}
                            value={state.tipovi}
                        />

                        { state.tipovi.length !== 0 && <Form.Dropdown
                            placeholder='Podtip'
                            fluid
                            multiple
                            selection
                            options={subTypesDropdown}
                            onChange={(e, { value }) => updateField('podTipovi', value)}
                            value={state.podTipovi}
                        />}

                    </Form.Group>



                    <Form.Group style={{display: 'flex', justifyContent: 'center'}} widths={"equal"}>
                        <Form.Dropdown
                            placeholder='Sortirajte po cijeni'
                            fluid
                            selection
                            options={priceSortList}
                            onChange={(e, { value }) => updateField('cijenaPoredak', value)}
                            value={state.cijenaPoredak}
                        />

                        <Form.Dropdown
                            placeholder='Sortirajte po m²'
                            fluid
                            selection
                            options={kvadraturaSortList}
                            onChange={(e, { value }) => updateField('kvadraturaPoredak', value)}
                            value={state.kvadraturaPoredak}
                        />

                        <Form.Dropdown
                            placeholder='Kategorija'
                            fluid
                            multiple
                            selection
                            options={kategorijaList}
                            onChange={(e, { value }) => updateField('kategorije', value)}
                            value={state.kategorije}
                        />

                    </Form.Group>


                    <Form.Group style={{display: 'flex', justifyContent: 'center'}} widths={"equal"} id="cijenaIkvadratura">

                        <Form.Field>
                            <label>Cijena od</label>
                            <Input label={"€"}
                                   labelPosition={"right"}
                                   id="cijenaMin"
                                   value={state.cijenaMin}
                                   placeholder="Npr: 500"
                                   onChange={(e) => handleChange(e, 'cijenaMin')}
                            />
                        </Form.Field>


                        <Form.Field>
                            <label>Cijena do</label>
                            <Input
                                label={"€"}
                                labelPosition={"right"}
                                id="priceMax"
                                value={state.cijenaMax}
                                placeholder="Npr: 1000"
                                onChange={(e) => handleChange(e, 'cijenaMax')}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Kvadratura od</label>
                            <Input
                                label={"m²"}
                                labelPosition={"right"}
                                id="livingAreaMin"
                                value={state.kvadraturaMin}
                                placeholder="Npr: 50"
                                onChange={(e) => handleChange(e, 'kvadraturaMin')}
                            />
                        </Form.Field>

                        <Form.Field>
                            <label>Kvadratura do</label>
                            <Input
                                label={"m²"}
                                labelPosition={"right"}
                                id="livingAreaMax"
                                value={state.kvadraturaMax}
                                placeholder="Npr: 80"
                                onChange={(e) => handleChange(e, 'kvadraturaMax')}
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group>

                        <Form.Button type='button' id="obrisi-uvjete" content='Obriši uvjete' onClick={() => {
                            setState(emptySearch)
                            navigate({
                                pathname: location.pathname,
                                search: ``
                            });

                        }}/>

                    </Form.Group>


                </Form>
            </div>


            {// todo: je li ovo nuzno?

            }

            <div className={`expandable-content ${expanded ? 'expanded' : ''}`} ref={sidebarRef}>
                <div className="search-form sidebar-form">
                    <h2 className="expandable-title">Proširena pretraga</h2>

                    <div className="input-row sidebar-input-row">
                        <div className="input-group sidebar-input-group">
                            <label className="form-label" htmlFor="bedrooms">Broj soba</label>
                            <input
                                key="bedrooms"
                                className="form-input"
                                id="bedrooms"
                                value={state.sobe}
                                onChange={(e) => handleChange(e, 'sobe')}
                            />
                        </div>

                        <div className="input-group sidebar-input-group">
                            <label className="form-label" htmlFor="bathrooms">Broj kupaonica</label>
                            <input
                                className="form-input"
                                id="bathrooms"
                                value={state.kupaonice}
                                onChange={(e) => handleChange(e, 'kupaonice')}
                            />
                        </div>
                    </div>

                    <div className="input-row sidebar-input-row">

                        <div className="input-group sidebar-input-group">
                            <label className="form-label" htmlFor="floor">Kat</label>
                            <input
                                className="form-input"
                                id="floor"
                                value={state.floor}
                                onChange={(e) => handleChange(e, 'floor')}
                            />
                        </div>

                        <div className="input-group sidebar-input-group">

                        </div>


                    </div>


                    <div className="input-row sidebar-input-row">
                        <div className="input-group sidebar-input-group">
                            <label className="form-label" htmlFor="balcony">Balkon/terasa</label>
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    id="balcony"
                                    checked={state.balcony}
                                    onChange={(e) => updateField('balkon', e.target.checked)}
                                />
                                <span className="custom-checkmark"></span>
                            </label>
                        </div>

                        <div className="input-group sidebar-input-group">
                            <label className="form-label" htmlFor="elevator">Dizalo</label>
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    id="elevator"
                                    checked={state.elevator}
                                    onChange={(e) => updateField('dizalo', e.target.checked)}
                                />
                                <span className="custom-checkmark"></span>
                            </label>
                        </div>
                    </div>

                    <div className="input-row sidebar-input-row">
                        <div className="input-group sidebar-input-group">
                            <label className="form-label" htmlFor="permit">Dozvola</label>
                            <label className="custom-checkbox">
                                <input
                                    type="checkbox"
                                    id="permit"
                                    className="custom-checkbox-input"
                                    checked={state.permit}
                                    onChange={(e) => updateField('dozvola', e.target.checked)}
                                />
                                <span className="custom-checkmark"></span>
                            </label>
                        </div>
                    </div>

                    <div className="search-component-desktop-button-container">
                        <div className="toggle-bar-expanded" onClick={() => setExpanded(!expanded)}>
                            <span>{expanded ? 'Sakrij' : 'Proširi'}</span>
                            {expanded ? <Icon name="arrow left"></Icon> : <Icon name="arrow right"></Icon>}
                        </div>

                        <div className="search-component-desktop-submit-expanded" onClick={ () => {
                            handleSubmit()
                            setExpanded(!expanded)
                        }}>
                            <span>Pretraga</span>
                            <Icon className="search-component-desktop-search-icon" name="search"></Icon>
                        </div>
                    </div>
                </div>

            </div>


            <div className="search-component-desktop-button-container">

                <div className={`toggle-bar ${expanded ? 'hide-button' : ''}`} onClick={() => setExpanded(!expanded)}
                     style={{pointerEvents: expanded ? 'none' : 'auto'}}>
                    <span>{expanded ? 'Sakrij' : 'Dodatno'}</span>
                    {expanded ? <Icon name="arrow left"></Icon> : <Icon name="arrow right"></Icon>}
                </div>


                <div className="search-component-desktop-submit" onClick={handleSubmit}>
                    <span>Pretraga</span>
                    <Icon className="search-component-desktop-search-icon" name="search"></Icon>
                </div>
            </div>
        </div>);
};

export default SearchComponentDesktop;