import "./helpers/NavigationDesktopHelper.css";
import { useEffect, useState } from "react";
import StickySliderComponentDesktop from "./helpers/StickyNavigationDesktop";
import NavigationDesktopHelper from "./helpers/NavigationDesktopHelper";

const NavigationDesktop = ({ currentPage }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div style={{ opacity: isScrolled ? 0 : 1, transition: 'opacity 0.3s ease-in-out' }}>
                <NavigationDesktopHelper currentPage={currentPage} />
            </div>
            <div style={{
                opacity: isScrolled ? 1 : 0,
                transition: 'opacity 0.3s ease-in-out',
                position: 'fixed',
                top: 0,
                zIndex: 10,
                width: '100%',
                left: 0   // Ensure it starts from the left edge
            }}>
                <StickySliderComponentDesktop currentPage={currentPage} />
            </div>

        </div>
    );
};

export default NavigationDesktop;
