import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSwipeable } from 'react-swipeable';

import "./Carousel.css";
import {baseURL} from "../../../api";

function Carousel(props) {

    const images = props.images;


    const leftoverImagesDesktop = images.length - 4;
    const leftoverImagesMobile = images.length - 1;

    const isMobile = useMediaQuery({ query: '(max-width: 790px)' });

    const [showModal, setShowModal] = useState(false);
    const [activeImageIndex, setActiveImageIndex] = useState(0);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight' && activeImageIndex <= images.length - 1) {
            if (activeImageIndex === images.length - 1) {
                setActiveImageIndex(0);
            } else {
                setActiveImageIndex(prevIndex => prevIndex + 1);
            }
        } else if (e.key === 'ArrowLeft' && activeImageIndex >= 0) {
            if (activeImageIndex === 0) {
                setActiveImageIndex(images.length - 1); // Ako je na prvoj slici, idi na zadnju
            } else {
                setActiveImageIndex(prevIndex => prevIndex - 1);
            }
        }
    };

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');  // This will ensure that the no-scroll class is removed even if the component unmounts with the modal open
        };
    }, [showModal]);


    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [activeImageIndex]);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (activeImageIndex <= images.length - 1) {
                if (activeImageIndex === images.length - 1) {
                    setActiveImageIndex(0);
                } else {
                    setActiveImageIndex(prevIndex => prevIndex + 1);
                }
            }
        },
        onSwipedRight: () => {
            if (activeImageIndex >= 0) {
                if (activeImageIndex === 0) {
                    setActiveImageIndex(images.length - 1); // Ako je na prvoj slici, idi na zadnju
                } else {
                    setActiveImageIndex(prevIndex => prevIndex - 1);
                }
            }
        }
    });

    const handleImageClick = (index) => {
        setShowModal(true);
        setActiveImageIndex(index);
    };

    const mobileLayout = isMobile  ? {
        display: "flex",
        flexDirection: "column"
    } : {}

    return (
        <>
            {isMobile ? (
                <div className="container">
                    <div className="image-grid-mobile">
                        <div className="image-item large" onClick={() => handleImageClick(0)}>
                            <img src={`${baseURL}/api/v1/image/${images[0]}`} alt="Real estate listing" />
                            {leftoverImagesMobile > 0 && (
                                <div className="overlay-mobile">
                                    <span>{`+${leftoverImagesMobile} dodatnih`}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container">
                    <div className="image-grid">
                        <div className="image-item large" onClick={() => handleImageClick(0)}>
                            <img src={`${baseURL}/api/v1/image/${images[0]}`} alt="Real estate listing" />
                        </div>
                        <div className="image-item small" onClick={() => handleImageClick(1)}>
                            <img src={`${baseURL}/api/v1/image/${images[1]}`} alt="Real estate listing" />
                        </div>
                        <div className="image-item small" onClick={() => handleImageClick(2)}>
                            <img src={`${baseURL}/api/v1/image/${images[2]}`} alt="Real estate listing" />
                        </div>
                        <div className="image-item tall" onClick={() => handleImageClick(3)}>
                            <img src={`${baseURL}/api/v1/image/${images[4]}`} alt="Real estate listing" />
                            {leftoverImagesDesktop > 0 && (
                                <div className="overlay">
                                    <span>{`+${leftoverImagesDesktop} dodatnih`}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <div {...handlers} className="image-modal-overlay" style={mobileLayout} onClick={() => setShowModal(false)}>
                    <img className="modal-image" src={`${baseURL}/api/v1/image/${images[activeImageIndex]}`} alt={`Real estate listing ${activeImageIndex}`} />

                    {!isMobile &&
                        <>
                            <button
                                className="modal-nav-button left"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (activeImageIndex === 0) {
                                        setActiveImageIndex(images.length - 1); // Ako je na prvoj slici, idi na zadnju
                                    } else {
                                        setActiveImageIndex(prevIndex => prevIndex - 1);
                                    }
                                }}>
                                &#8592; {/* Strelica lijevo */}
                            </button>
                            <button
                                className="modal-nav-button right"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (activeImageIndex === images.length - 1) {
                                        setActiveImageIndex(0); // Ako je na zadnjoj slici, idi na prvu
                                    } else {
                                        setActiveImageIndex(prevIndex => prevIndex + 1);
                                    }
                                }}>
                                &#8594; {/* Strelica desno */}
                            </button>
                        </>
                    }

                    {isMobile &&
                        <div>
                            <button
                                className="modal-nav-button-mobile left-mobile"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (activeImageIndex === 0) {
                                        setActiveImageIndex(images.length - 1); // Ako je na prvoj slici, idi na zadnju
                                    } else {
                                        setActiveImageIndex(prevIndex => prevIndex - 1);
                                    }
                                }}>
                                &#8592; {/* Strelica lijevo */}
                            </button>
                            <button
                                className="modal-nav-button-mobile right-mobilee"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (activeImageIndex === images.length - 1) {
                                        setActiveImageIndex(0); // Ako je na zadnjoj slici, idi na prvu
                                    } else {
                                        setActiveImageIndex(prevIndex => prevIndex + 1);
                                    }
                                }}>
                                &#8594; {/* Strelica desno */}
                            </button>
                        </div>
                    }
                </div>
            )}

        </>
    );
}

export default Carousel;
