import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
    token: "",
    isLoggedIn: false,
    username: "",
    roles: [],
    authorities: [],
    login: (token) => {},
    logout: () => {},
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState("");
    const [username, setUsername] = useState("");
    const [roles, setRoles] = useState([]);
    const [authorities, setAuthorities] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const logoutHandler = () => {
        console.log("logging out...");
        setToken("");
        setAuthorities([]);
        setRoles([]);
        setUsername("");
        setIsLoggedIn(false);
        localStorage.removeItem("token");
    };

    const loginHandler = (token) => {
        if (!token) {
            logoutHandler();
            return;
        }
        console.log("logging in...");
        const decodedToken = decodeJWTToken(token);
        const { sub: username, role, authorities, iat, exp } = decodedToken;

        if (!isTokenExpired(exp, iat)) {
            setToken(token);
            localStorage.setItem("token", token);
            setUsername(username);
            setRoles(role);
            setAuthorities(authorities);
            setIsLoggedIn(true);
        } else {
            logoutHandler();
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
            loginHandler(storedToken);
        } else {
            logoutHandler();
        }
        setIsLoading(false); // Set loading to false after checking the token
    }, []);



    const contextValue = {
        token: token,
        isLoggedIn: isLoggedIn,
        username: username,
        roles: roles,
        authorities: authorities,
        isLoading: isLoading,
        login: loginHandler,
        logout: logoutHandler,
    };

    useEffect(() => {
        console.log("user: ", token, username, roles, authorities, isLoggedIn);
    }, [token, username, roles, authorities, isLoggedIn]);

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

const decodeJWTToken = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
    );
    const payload = JSON.parse(jsonPayload);
    const issuedAt = new Date(payload.iat * 1000);
    const expiration = new Date(payload.exp * 1000);
    return { ...payload, issuedAt, expiration };
};

const isTokenExpired = (exp, iat) => {
    const now = Math.floor(Date.now() / 1000);

    if (now > exp) {
        // Token has expired
        return true;
    }

    if (now < iat) {
        // Token is not valid yet
        return true;
    }

    // Token is still valid
    return false;
};


export const useAuth = () => {
    return React.useContext(AuthContext);
};

export default AuthContext;
