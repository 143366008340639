import React, {useEffect, useState} from 'react';
import {Button, Form, Icon, Message} from 'semantic-ui-react';
import CreateHouse from "../components/real-estate-listing-create/CreateHouse";
import NavigationComponent from "../components/navigation/NavigationComponent";
import FooterComponent from "../components/footer/FooterComponent";
import CreateApartment from "../components/real-estate-listing-create/CreateApartment";
import {useLocation, useNavigate} from "react-router-dom";
import CreatePoslovniProstor from "../components/real-estate-listing-create/CreatePoslovniProstor";
import CreateZemljiste from "../components/real-estate-listing-create/CreateZemljiste";


const UpdateListing = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Ažuriraj oglas - Gorica Nekretnine";
    }, []);


    const [listingType, setListingType] = useState('');
    const [listingId, setListingId] = useState()


    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let paths = location.pathname.split("/")
        let id = paths[paths.length - 2] + "/" + paths[paths.length - 1]
        setListingId(id)
        setListingType(paths[paths.length - 2].toUpperCase())
    }, []);

    function setId(id) {
        setListingId(id)
        setListingType('')
    }

    return (
        <>
            <NavigationComponent/>

            {listingType && <Message icon info style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                transition: 'opacity 1.5s ease-in-out',
                marginBottom: '50px'
            }}>
                <Icon name='info'/>
                <Message.Content>
                    <Message.Header>
                       Oglas i slike se uređuju odvojeno
                    </Message.Header>
                    Za oglas, samo uredite ono što želite, stare vrijednosti ostati će nepromijenjene
                </Message.Content>
            </Message>
            }

            {listingType === '' && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                    marginBottom: '50px',
                    marginTop: '50px'
                }}>
                    <Message icon success style={{
                        width: '50%',
                        margin: 'auto',
                        transition: 'opacity 1.5s ease-in-out'
                    }}>
                        <Icon name='info'/>
                        <Message.Content>
                            <Message.Header>
                                Oglas uspješno spremljen.
                            </Message.Header>
                            <Button style={{marginTop: '10px'}} color={"green"} onClick={() => navigate("/nekretnina/" + listingId)}>
                                Pogledaj oglas {listingId}
                            </Button>
                        </Message.Content>
                    </Message>
                </div>
            )}




            <Form>
                {listingType === 'KUCA' && <CreateHouse workMode="update" listingId={listingId} setId={setId} />}
                {listingType === 'STAN' && <CreateApartment workMode="update" listingId={listingId} setId={setId}/>}
                {listingType === 'POSLOVNI_PROSTOR' && <CreatePoslovniProstor workMode="update" listingId={listingId} setId={setId}/>}
                {listingType === 'ZEMLJISTE' && <CreateZemljiste workMode="update" listingId={listingId} setId={setId}/>}

            </Form>

            <FooterComponent/>
        </>
    );
};

export default UpdateListing;
