import React from 'react';
import './FooterComponent.css';

function CompanyFooter() {
    return (
        <footer>
            <div className="footer">
                <div className="footer-section">
                    <div className="footer-logo">Gorica Nekretnine</div>
                    <p>Pronađite dom iz snova.</p>
                    <a href="https://www.hgk.hr">
                        <img src={`${process.env.PUBLIC_URL}/hgk.png`} alt="Gospodarska komora" className="nav-dsktp-logo-img"/>
                    </a>
                    <br/>
                    <a href="https://oss.uredjenazemlja.hr/">
                        <img src={`${process.env.PUBLIC_URL}/katastar.png`} alt="Katastar" className="nav-dsktp-logo-img"/>
                    </a>
                    <p><b>Licencirana agencija za nekretnine.</b></p>

                </div>

                <div className="footer-section">
                    <div className="footer-title">Brza pretraga</div>
                    <a className="footer-link" href="/pretraga">Pretraga</a>
                    <a className="footer-link" href="/kontakt">Kontakt</a>
                </div>

                <div className="footer-section">
                    <div className="footer-title">Kontakt</div>
                    <a className="footer-link" href="mailto:info@gorica-nekretnine.hr">info@gorica-nekretnine.hr</a>
                    <a className="footer-link" href="tel:+385995550110">+385 99 555 0110</a>
                    <a className="footer-link" href="tel:+385915351146">+385 91 535 1146</a>
                    <a className="footer-link" href="tel:+385995550106">+385 99 555 0106</a>
                    <p>Lokacija: Trg Kralja Tomislava 41, 10410 V. Gorica</p>
                </div>


                <div className="footer-section">
                    <div className="footer-title">Privatnost</div>
                    <a className="footer-link" href="/politika-privatnosti">Politika privatnosti</a>
                </div>
            </div>

            <div className="footer-rights">
                <p style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    marginBottom: "5px",
                    marginTop: "5px"
                }}>© {new Date().getFullYear()} Gorica Nekretnine Nuković d.o.o. Sva prava pridržana.</p>
                <p>Designed and developed by Magic Agency</p>
            </div>

        </footer>
    );
}

export default CompanyFooter;
