import React, {useEffect, useState} from 'react';
import {Form, Icon, Message, Select} from 'semantic-ui-react';
import CreateHouse from "../components/real-estate-listing-create/CreateHouse";
import NavigationComponent from "../components/navigation/NavigationComponent";
import FooterComponent from "../components/footer/FooterComponent";
import CreateApartment from "../components/real-estate-listing-create/CreateApartment";
import ImageUpload from "../components/real-estate-listing-image-upload/ImageUpload";
import CreatePoslovniProstor from "../components/real-estate-listing-create/CreatePoslovniProstor";
import CreateZemljiste from "../components/real-estate-listing-create/CreateZemljiste";

const listingOptions = [
    { key: 'house', text: 'Kuća', value: 'KUCA' },
    { key: 'apartment', text: 'Stan', value: 'STAN' },
    { key: 'poslovni_prostor', text: 'Poslovni prostor', value: 'POSLOVNI_PROSTOR' },
    { key: 'zemljiste', text: 'Zemljište', value: 'ZEMLJISTE' },
];

const CreateListing = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Objavi nekretninu - Gorica Nekretnine";
    }, []);


    const [listingType, setListingType] = useState('');
    const [listingId, setListingId] = useState()

    function setId(id) {
        setListingId(id)
        setListingType('')
    }

    const handleSelectChange = (e, { value }) => {
        setListingType(value);
    };

    return (
        <>
            <NavigationComponent/>


            {!listingId &&
                <div style={{
                    width: "50%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}>
                    <Form>
                        <Form.Field>
                            <label>Vrsta</label>
                            <Select
                                name="category"
                                options={listingOptions}
                                placeholder='Izaberite vrstu'
                                onChange={handleSelectChange}
                            />
                        </Form.Field>
                    </Form>
                </div>
            }

            {listingType === '' && <Message icon info style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                transition: 'opacity 1.5s ease-in-out',
                marginBottom: '50px'
            }}>
                <Icon name='info'/>
                <Message.Content>
                    <Message.Header>
                        {!listingId &&<span>Prvo odaberite vrstu oglasa:</span> }
                        {listingId &&<span>Sada prenesite slike</span> }

                    </Message.Header>
                    {!listingId &&<span>Npr: kuća, stan:</span> }
                    {listingId &&<span>Odaberite slike i pritisnite Upload</span> }
                </Message.Content>
            </Message>}



            <Form>
                {listingType === 'KUCA' && <CreateHouse setId={setId}/>}
                {listingType === 'STAN' && <CreateApartment setId={setId}/>}
                {listingType === 'POSLOVNI_PROSTOR' && <CreatePoslovniProstor setId={setId}/>}
                {listingType === 'ZEMLJISTE' && <CreateZemljiste setId={setId}/>}
                {listingId !== undefined && <ImageUpload oglas_id={listingId}/>}

            </Form>

            <FooterComponent/>
        </>
    );
};

export default CreateListing;
