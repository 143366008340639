import React, {Fragment} from 'react';
import RealEstatePreview from "../real-estate-listing-preview/RealEstatePreview";
import './RealEstatePreviewContainer.css';
import LoadingSpinner from "../spinner/LoadingSpinner";



const nothingFound = `${process.env.PUBLIC_URL}/nothing-found.png`

const RealEstatePreviewContainer = ({ listings, headline, paragraphText, isLoading = false , id = null}) => {
    const addMargin = headline && paragraphText;

    let idToSet;
    if (id) {
        idToSet = id;
    }

    const renderListingsOrLoader = () =>{
        if (isLoading) {
            return <LoadingSpinner/>
        }
        else if (listings.length === 0) {
            return (

                    <div className="nothing-found">
                        <img  src={nothingFound} alt="Nema rezultata pretrage."></img>
                    </div>

            )
        }
        else {
            return (
                <div
                    className={`real-estate-preview-container-listings-container ${addMargin ? '' : 'real-estate-preview-container-margin-top-class'}`}>
                    {listings.map((listing, index) => (
                        <RealEstatePreview key={index} listing={listing} index={index}/>
                    ))}
                </div>
            )
        }
    }

    return (
            <div className="real-estate-preview-container">
                {addMargin &&
                    <div className="real-estate-preview-container-header">
                        <h2 id={idToSet}>{headline}</h2>
                        <p>{paragraphText}</p>
                    </div>
                }
                {renderListingsOrLoader()}
            </div>
        );
};

export default RealEstatePreviewContainer;
