import {Fragment, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import NavigationComponent from "../components/navigation/NavigationComponent";
import RealEstatePreviewContainer from "../components/real-estate-listing-preview-container/RealEstatePreviewContainer";
import FooterComponent from "../components/footer/FooterComponent";
import SearchComponentDesktop from "../components/search/desktop/SearchComponentDesktop";
import {pretraziOglase} from "../api";
import {Pagination} from "semantic-ui-react";

const SearchPage = () => {
    useEffect(() => {
        if (!window.location.hash) {
            window.scrollTo(0, 0);
        }
        document.title = "Pretraga - Gorica Nekretnine";
    }, []);

    const scrollRef = useRef(null);

    setTimeout(() => {
        if (window.location.hash) {
            console.log(window.location.hash)
            handleScrollToElement()
        }
    }, 100)

    const [isLoading, setIsLoading] = useState(true);

    const handleScrollToElement = () => {
        if (scrollRef.current) {
            const element = scrollRef.current.querySelector('#rezultati-pretrage-id');
            if (element) {
                const offsetTop = element.getBoundingClientRect().top + window.pageYOffset;
                const offset = 100;
                window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
            }
        }
    };


    const location = useLocation();

    const [listings, setListings] = useState([]);


    const [urlParams, setUrlParams] = useState();


    useEffect(() => {
        setUrlParams(new URLSearchParams(location.search))
    }, [location.search]);


    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPageNumber(activePage);
    };


    useEffect(() => {
        console.log(currentPageNumber)
        setIsLoading(true)
        pretraziOglase(urlParams, currentPageNumber-1, "3").then(r => {
            setListings(r)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }, [urlParams, currentPageNumber]);

    return (
        <Fragment>
            <NavigationComponent currentPage={"search"}></NavigationComponent>
            <div ref={scrollRef}>
                {urlParams && <SearchComponentDesktop onInitiateScroll={handleScrollToElement}
                                          searchParams={urlParams}/>}
                <RealEstatePreviewContainer listings={listings}
                                            id={"rezultati-pretrage-id"}
                                            headline={"Rezultati pretrage"}
                                            paragraphText={"Nastavite s pretragom ili ažurirajte uvjete pretrage"}
                                            isLoading={isLoading}
                />
                <div style={{display: "flex", justifyContent: "center", marginBottom: "25px"}}>
                    {(urlParams && urlParams.toLocaleString()) && <Pagination
                            onPageChange={handlePaginationChange}
                            boundaryRange={0}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={1}
                            totalPages={10}/>}
                </div>
            </div>
            <FooterComponent/>
        </Fragment>
    );
};

export default SearchPage;
