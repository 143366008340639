import { useMediaQuery } from "react-responsive";
import NavigationDesktop from "./desktop/NavigationDesktop";
import NavigationMobile from "./mobile/NavigationMobile"
const NavigationComponent = ({ currentPage }) => {
    const isDesktop = useMediaQuery({ minWidth: 930 });
    const isMobile = useMediaQuery({ maxWidth: 929 });
    return (
        <>
            {isDesktop && <NavigationDesktop currentPage={currentPage} />}
            {isMobile && <NavigationMobile currentPage={currentPage} />}
        </>
    );
};

export default NavigationComponent;
