import React, { useState } from 'react';
import './SearchHeroMobile.css';
import { Icon } from "semantic-ui-react";
import {recommendations} from "../../../mockData";
import {Link} from "react-router-dom";

const SearchHeroMobile = () => {

    const [selectedOption, setSelectedOption] = useState('Najam');
    const [searchValue, setSearchValue] = useState('Grad Zagreb');


    return (
        <div className="search-hero-mobile-container">
            <h2 className="search-hero-mobile-title">Otkrijte savršenu nekretninu</h2>
            <p className="search-hero-mobile-description">Širok izbor nekretnina na jednome mjestu</p>

            <div className="search-hero-mobile-buttons">
                {['Najam', 'Prodaja'].map(option => (
                    <button
                        key={option}
                        onClick={() => setSelectedOption(option)}
                        className={`search-hero-mobile-option ${selectedOption === option ? 'active' : ''}`}
                    >
                        {option}
                    </button>
                ))}
            </div>

            <div className="search-hero-mobile-input-group">
                <input
                    type="text"
                    placeholder="Unesite pojam..."
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <Link
                    to={{pathname: "/pretraga",
                        search: `?gradovi=${searchValue}&kategorije=${selectedOption.toUpperCase()}`,
                        hash:  "rezultati-pretrage-id"}}>
                    <button><Icon name="search" /></button>
                </Link>

            </div>

            <div className="search-hero-mobile-recommendations">
                    {recommendations.map(item => (
                        <button
                            key={item}
                            onClick={() => setSearchValue(item)}
                            className={item === searchValue ? 'active-mobile' : ''}
                        >
                            {item}
                        </button>
                    ))}
            </div>
        </div>
    );
};

export default SearchHeroMobile;
