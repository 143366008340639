import React, {useEffect} from 'react';
import './RealEstatePreview.css';
import {KUCA, POSLOVNI_PROSTOR, STAN, ZEMLJISTE} from "../../mockData";
import {Link} from "react-router-dom";
import {baseURL} from "../../api";

const RealEstatePreview = ({ listing, index }) => {


    useEffect(() => {
        const timer = setTimeout(() => {
            const elements = document.querySelectorAll('.real-estate-preview-container-listing-preview');
            if (elements[index]) {
                elements[index].classList.add('animate');
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [index]);

    const renderDetails = () => {
        switch (listing.additionalInfo.type) {
            case KUCA:
                return (
                    <div className="real-estate-preview-listing-details">
                        <span>{listing.additionalInfo.bedrooms} sobe</span>
                        <span>{listing.additionalInfo.bathrooms} kupaone</span>
                        <span>{listing.additionalInfo.livingArea} m2</span>
                    </div>
                );
            case STAN:
                return (
                    <div className="real-estate-preview-listing-details">
                        <span>{listing.additionalInfo.bedrooms} sobe</span>
                        <span>{listing.additionalInfo.bathrooms} kupaone</span>
                        <span>{listing.additionalInfo.floor}. kat</span>
                        <span>{listing.additionalInfo.livingArea} m2</span>
                    </div>
                );
            case ZEMLJISTE:
                return (
                    <div className="real-estate-preview-listing-details">
                        <span>{listing.additionalInfo.landArea} m2</span>
                        <span>{listing.additionalInfo.permit ? "Gradevinsko: da" : "Gradevinsko: ne"}</span>

                    </div>
                );
            case POSLOVNI_PROSTOR:
                return (
                    <div className="real-estate-preview-listing-details">
                        <span>{listing.additionalInfo.livingArea} m2</span>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <Link to={"/nekretnina/"+ listing.id}>
            <div className="real-estate-preview-container-listing-preview">
                <img className="real-estate-preview-listing-image" src={`${baseURL}/api/v1/image/${listing.images[0]}`} alt={listing.title} />
                <h2 className="real-estate-preview-listing-title">{listing.title}</h2>
                <p className="real-estate-preview-listing-price">{`${listing.price} €`}</p>
                <p className="real-estate-preview-listing-location">{`${listing.location.city}, ${listing.location.neighbourhood}`}</p>
                {renderDetails()}
                <button className="real-estate-preview-listing-button">Detaljnije</button>
            </div>
        </Link>
    );
};

export default RealEstatePreview;
