
export const NAJAM = "NAJAM"
export const PRODAJA = "PRODAJA"

export const POSLOVNI_PROSTOR = "POSLOVNI_PROSTOR"

export const KUCA = "KUCA"
export const STAN = "STAN"
export const ZEMLJISTE = "ZEMLJISTE"

export const NOVOGRADNJA = "NOVOGRADNJA"

export const STAROGRADNJA = "STAROGRADNJA"

export const constructionTypeOptions = [
    { key: 'n', text: 'Novogradnja', value: NOVOGRADNJA },
    { key: 's', text: 'Starogradnja', value: STAROGRADNJA },
];

export const categoryOptions = [
    { key: 'n', text: 'Najam', value: NAJAM },
    { key: 'p', text: 'Prodaja', value: PRODAJA },
];

export const recommendations = ['Grad Zagreb', 'Zagrebačka županija', 'Dalmacija', 'Istra i Kvarner'];


export const priceSortList = [{
    key: "ASC", text: "Niže prema više", value: "ASC"
}, {
    key: "DESC", text: "Više prema niže", value: "DESC"
},]

export const kvadraturaSortList = [{
    key: "ASC", text: "Niže prema više", value: "ASC"
}, {
    key: "DESC", text: "Više prema niže", value: "DESC"
},]

export const typesList = [{
    key: "KUCA", text: "Kuća", value: "KUCA"
}, {
    key: "STAN", text: "Stan", value: "STAN"
},
    {
        key: "POSLOVNI_PROSTOR", text: "Poslovni prostor", value: "POSLOVNI_PROSTOR"
    },{
        key: "ZEMLJISTE", text: "Zemljište", value: "ZEMLJISTE"
    }
]

export const kategorijaList = [{
    key: "NAJAM", text: "Najam", value: "NAJAM"
}, {
    key: "PRODAJA", text: "Prodaja", value: "PRODAJA"
}]

export const kucaSubTypeList = [
    {key: "KUCA_ZA_ODMOR", text: "Kuća za odmor", value: "KUCA_ZA_ODMOR"},
    {key: "VILLA", text: "Villa", value: "VILLA"},
    {key: "VIKENDICA", text: "Vikendica", value: "VIKENDICA"}
]


export const stanSubTypeList = [
    {key: "APARTMAN", text: "Apartman", value: "APARTMAN"}
]