import React, {useEffect, useState} from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import NavigationComponent from "../components/navigation/NavigationComponent";
import FooterComponent from "../components/footer/FooterComponent";
import {useMediaQuery} from "react-responsive";

const FaqPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Često postavljena pitanja - Gorica Nekretnine";
    }, []);


    const isMobile = useMediaQuery({ query: '(max-width: 940px)' });

    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const style = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
        flexDirection: "column"
    }

    const textStyle = !isMobile ? {
        fontSize: "18px"
    } :
        {
            fontSize: "16px"
        }

    const descriptionStyle = !isMobile ? {
        fontSize: "16px"
    } : {
        fontSize: "14px"
    }

    const accStyle = !isMobile ? {width: "65%"} : {width: "90%"}

    const h2Style = !isMobile ? {marginBottom: "50px", fontSize: "30px"}
        : {marginBottom: "15px", fontSize: "20px"}

    return (
        <>
          <NavigationComponent currentPage={"faq"}/>
            <div style={style}>
                <h2 style={h2Style}>Često postavljena pitanja</h2>
                <Accordion styled style={accStyle}>
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleClick}
                        style={textStyle}
                    >
                        <Icon name='dropdown' />
                        Kako prenijeti račune za komunalije na svoje ime?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0} style={descriptionStyle}>
                        <p>
                            Nakon što nekretnina bude prebačena na Vas, možete prenijeti račune na način, da priložite dokumentaciju kupoprodajnog ugovora. Prije primopredaje, prodavatelj se treba pobrinuti da svi računi i zaostaci do datuma primopredaje budu plaćeni. Svakako, uz našu asistenciju, potruditi ćemo se da sve prođe jednostavno u najkraćem mogućem vremenu.
                        </p>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleClick}
                        style={textStyle}
                    >
                        <Icon name='dropdown' />
                        Što je tabluarna izjava?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1} style={descriptionStyle}>
                        <p>
                            Tabluarna izjava je dokument kojim se potvrđuje da je prodavatelj zaprimio kupoprodajni iznos od kupca i služi kako bi se kupac mogao upisati u zemljišnje knjige kao novi vlasnik nekretnine. Drugim riječima, to je dokument koji služi kao dokaz da je nekretnina prodana i da je iznos koji kupac treba podmiriti prodavatelju, isplaćen. Dokument mora biti ovjeren od strane javnog bilježnika kako bi bio pravomoćan, a time valjan. Tabluarna izjava se predaje tek nakon što se napravi isplata ,kupca, prodavatelju i sredstva su dostupna na raspolaganje prodavatelju.
                        </p>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={handleClick}
                        style={textStyle}

                    >
                        <Icon name='dropdown' />
                        Što je ugovor o kupoprodaji nekretnine?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2} style={descriptionStyle}>
                        <p>
                            Ugovor o kupoprodaji nekretnine je ugovor kojim se prodavatelj obvezuje da će na kupca prenijeti vlasništvo nekretnine, a kupac se obvezuje da će platiti ugovorenu kupoprodajnu cijenu nekretnine. Kupac je obvezan isplatiti prodavatelju cjelokupan iznos ugovorene kupoprodajne cijene, a prodavatelj je obvezan predati kupcu u posjed predmetnu nekretninu i dozvoliti prijenos prava vlasništva na ime novog vlasnika (kupca).
                        </p>
                    </Accordion.Content>

                    <Accordion.Title
                        active={activeIndex === 3}
                        index={3}
                        onClick={handleClick}
                        style={textStyle}

                    >
                        <Icon name='dropdown' />
                        Koja je dokumentacija potrebna za kupnju nekretnine u Hrvatskoj?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3} style={descriptionStyle}>
                        <p>
                            Pri kupnji nekretnine u Hrvatskoj kupac mora predočiti dokaz o državljanstvu Republike Hrvatske i to uz predočenje osobne iskaznice, putovnice ili domovnice, u originalu ili ovjerenu fotokopiju kod javnog bilježnika i rješenje o osobnom identifikacijskom broju (OIB). Isto vrijedi i za strane državljane koji moraju predočiti iz svoje matične zemlje dokaz o državljanstvu i rješenje o OIB-u dobivenom u Republici Hrvatskoj.
                        </p>
                    </Accordion.Content>


                </Accordion>
            </div>
            <FooterComponent />
        </>
    );
};

export default FaqPage;
