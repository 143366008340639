import React, {useContext, useEffect, useState} from 'react';
import {Form, Button, Checkbox, Input, Select, Icon} from 'semantic-ui-react';
import {
    azurirajStan,
    dohvatiGradskuListu,
    dohvatiNeighbourhoodListu, dohvatiStan,
    stvoriStan
} from "../../api";
import ImageUpload from "../real-estate-listing-image-upload/ImageUpload";
import {categoryOptions, constructionTypeOptions, stanSubTypeList} from "../../mockData";
import authContext from "../../auth/auth-context";


const CreateApartment = ({setId, workMode = "create", listingId}) => {

    const context = useContext(authContext)
    const [cities, setCities] = useState([]);
    const [neighs, setNeighs] = useState([]);

    useEffect(() => {
        if (workMode === 'update') {
            dohvatiStan(listingId).then(r => {
                setApartmentData(r)
            })
        }

        let uniqueNeighs = new Set();

        dohvatiGradskuListu().then(r => {
            setCities(r);
            r.forEach(grad => {
                dohvatiNeighbourhoodListu(grad.value).then(res => {
                    res.forEach(neigh => uniqueNeighs.add(neigh));
                    setNeighs([...uniqueNeighs]);
                });
            });
        });
    }, []);

    const [apartmentData, setApartmentData] = useState({
        title: '',
        price: '',
        location: {
            city: '',
            neighbourhood: ''
        },
        category: '',
        additionalInfo: {
            type: 'STAN',
            subType: '',
            bedrooms: '',
            bathrooms: '',
            livingArea: '',
            balcony: false,
            elevator: false,
            floor: '',
            construction: {
                constructionType: '',
                constructionYear: '',
                renovationYear: ''
            }
        },
        promoted: false,
        userAddedAdditionalInfo: [],
        description: ''
    });

    const handleChange = (e, { name, value, checked }) => {
        if (name === 'city' || name === 'neighbourhood') {
            setApartmentData(prevData => ({
                ...prevData,
                location: { ...prevData.location, [name]: value }
            }));
        } else if (['bedrooms', 'bathrooms', 'livingArea', 'floor', 'subType'].includes(name)) {
            setApartmentData(prevData => ({
                ...prevData,
                additionalInfo: { ...prevData.additionalInfo, [name]: value }
            }));
        } else if (['constructionType', 'constructionYear', 'renovationYear'].includes(name)) {
            setApartmentData(prevData => ({
                ...prevData,
                additionalInfo: {
                    ...prevData.additionalInfo,
                    construction: { ...prevData.additionalInfo.construction, [name]: value }
                }
            }));
        } else if (name === 'balcony' || name === 'elevator' || name === 'promoted') {
            setApartmentData(prevData => ({
                ...prevData,
                additionalInfo: { ...prevData.additionalInfo, [name]: checked },
                [name === 'promoted' ? 'promoted' : 'additionalInfo']: name === 'promoted' ? checked : { ...prevData.additionalInfo, [name]: checked }
            }));
        } else if (name === 'userAddedInfo') {
            const infoArray = value.split('\n');
            setApartmentData(prevData => ({
                ...prevData,
                userAddedAdditionalInfo: infoArray
            }));
        } else {
            setApartmentData(prevData => ({ ...prevData, [name]: value }));
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (workMode === 'create') {
            stvoriStan(apartmentData, context.token).then(r => setId(r));
        } else if (workMode === 'update') {
            azurirajStan(apartmentData, listingId, context.token).then(r => setId(r));
        }
    };

    return (
        <>
            <Form style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "50px"
            }}>
                <Form.Field>
                    <label>Naslov</label>
                    <Input name="title" placeholder='Naslov' onChange={handleChange} value={apartmentData.title}/>
                </Form.Field>
                <Form.Field>
                    <label>Podvrsta</label>
                    <Select name="subType" options={stanSubTypeList} placeholder='Podvrsta'
                            onChange={handleChange} value={apartmentData.additionalInfo.subType} />
                </Form.Field>
                <Form.Field>
                    <label>Cijena</label>
                    <Input name="price" placeholder='Cijena' type='number' onChange={handleChange} value={apartmentData.price} />
                </Form.Field>
                <Form.Field>
                    <label>Grad</label>
                    <Select name="city" search options={cities || []} placeholder='Gradovi' onChange={handleChange} value={apartmentData.location.city} />
                </Form.Field>
                <Form.Field>
                    <label>Četvrt</label>
                    <Select name="neighbourhood" search options={neighs || []} placeholder='Naselja' onChange={handleChange} value={apartmentData.location.neighbourhood} />
                </Form.Field>
                <Form.Field>
                    <label>Kategorija</label>
                    <Select name="category" options={categoryOptions} placeholder='Izaberite kategoriju' onChange={handleChange} value={apartmentData.category} />
                </Form.Field>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Broj soba' name='bedrooms' placeholder='Broj soba' type='number'
                                onChange={handleChange} value={apartmentData.additionalInfo.bedrooms} />
                    <Form.Input fluid label='Broj kupaonica' name='bathrooms' placeholder='Broj kupaonica' type='number'
                                onChange={handleChange} value={apartmentData.additionalInfo.bathrooms} />
                </Form.Group>
                <Form.Input fluid label='Stambena površina' name='livingArea' placeholder='Stambena površina (m²)' type='number'
                            onChange={handleChange} value={apartmentData.additionalInfo.livingArea} />
                <Form.Field>
                    <Checkbox label='Balkon' name="balcony" onChange={(e,
                                                                       { checked }) =>
                        setApartmentData({ ...apartmentData, additionalInfo: { ...apartmentData.additionalInfo, balcony: checked }})}
                    checked={apartmentData.additionalInfo.balcony}/>
                </Form.Field>
                <Form.Field>
                    <Checkbox label='Dizalo' name="elevator"
                              onChange={(e, { checked }) =>
                                  setApartmentData({ ...apartmentData, additionalInfo: { ...apartmentData.additionalInfo, elevator: checked }})}
                              checked={apartmentData.additionalInfo.elevator}/>
                </Form.Field>
                <Form.Input fluid label='Kat' name='floor' placeholder='Kat' type='number' onChange={handleChange} value={apartmentData.additionalInfo.floor}/>
                <Form.Field>
                    <label>Vrsta gradnje</label>
                    <Select name="constructionType" options={constructionTypeOptions} placeholder='Izaberite vrstu gradnje'
                            onChange={handleChange} value={apartmentData.additionalInfo.construction.constructionType} />
                </Form.Field>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Godina gradnje' name='constructionYear' placeholder='Godina gradnje' type='number'
                                onChange={handleChange}
                                value={apartmentData.additionalInfo.construction.constructionYear}/>
                    <Form.Input fluid label='Godina renovacije' name='renovationYear' placeholder='Godina renovacije' type='number'
                                onChange={handleChange} value={apartmentData.additionalInfo.construction.renovationYear}/>
                </Form.Group>
                <Form.Field>
                    <Checkbox label='Istaknuti oglas?' name="promoted"
                              onChange={(e, { checked }) =>
                                  setApartmentData({ ...apartmentData, promoted: checked })}
                    checked={apartmentData.promoted}/>
                </Form.Field>
                <Form.TextArea label='Dodatne informacije' name="userAddedInfo" placeholder='Unesite dodatne informacije, svaku u svoj red'
                               onChange={handleChange}
                               value={apartmentData.userAddedAdditionalInfo.join('\n')}
                />
                <Form.TextArea label='Opis' name="description" placeholder='Opis nekretnine...'
                               onChange={handleChange} value={apartmentData.description}/>

                <Button animated={"fade"}   onClick={handleSubmit} color="green" >
                    <Button.Content visible> {workMode === 'create' ? 'Kreiraj Oglas' : 'Ažuriraj Oglas'}</Button.Content>
                    <Button.Content hidden><Icon name='right arrow' /></Button.Content>
                </Button>
            </Form>

            {apartmentData.id && <ImageUpload oglas_id={apartmentData.id}/>}
        </>
    );
};

export default CreateApartment;
