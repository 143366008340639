import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "./auth-context";
import LoadingSpinner from "../components/spinner/LoadingSpinner";

export const ProtectedRoute = (props) => {
    const authCtx = useContext(AuthContext);

    if (authCtx.isLoading) {
        return <LoadingSpinner/>
    }

    if (!authCtx.isLoggedIn) {
        return <Navigate to="/prijava" replace />;
    }

    return props.children;

};

export default ProtectedRoute;
