import { useMediaQuery } from "react-responsive";
import SearchHeroDesktop from "./desktop/SearchHeroDesktop";
import SearchHeroMobile from "./mobile/SearchHeroMobile";
const SearchHeroComponent = ({ currentPage }) => {
    const isDesktop = useMediaQuery({ minWidth: 930 });
    const isMobile = useMediaQuery({ maxWidth: 929 });
    return (
        <>
            {isDesktop && <SearchHeroDesktop/>}
            {isMobile && <SearchHeroMobile/>}
        </>
    );
};

export default SearchHeroComponent;
