import { Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import ProtectedRoute from "./auth/ProtectedRoute"
import HomePage from "./pages/HomePage";
import SearchPage from "./pages/SearchPage";
import RealEstateListingFullViewPage from "./pages/RealEstateListingFullViewPage";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import CreateListing from "./pages/CreateListing";
import UpdateListing from "./pages/UpdateListing";
import FaqPage from "./pages/FaqPage";
import ContactPage from "./pages/ContactPage";


function App() {
  return (
      <Fragment>
        <Routes>
          <Route path="/" element={<HomePage />}/>
          <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
          />
            <Route
                path="/pretraga"
                element={<SearchPage />}
            />
            <Route
                path="/nekretnina/:type/:id"
                element={
                        <RealEstateListingFullViewPage />
                }
            />
            <Route
                path="/prijava"
                element={
                <LoginPage/>
                }
            />
            <Route
                path="/novi_oglas"
                element={
                    <ProtectedRoute>
                        <CreateListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/uredi_oglas/:type/:id"
                element={
                    <ProtectedRoute>
                        <UpdateListing />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/cesto-postavljena-pitanja"
                element={
                    <FaqPage/>
                }
            />

            <Route
                path="/kontakt"
                element={
                    <ContactPage/>
                }
            />
        </Routes>
      </Fragment>
  );
}

export default App;
