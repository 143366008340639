import React, { useState } from 'react';
import './SearchHeroDesktop.css';
import { Icon } from "semantic-ui-react";
import {Link} from "react-router-dom";
import {recommendations} from "../../../mockData";

const SearchHeroDesktop = () => {

    const [selectedOption, setSelectedOption] = useState('Najam');
    const [searchValue, setSearchValue] = useState('Grad Zagreb');


    return (
        <>
            <div className="search-hero-hero-section">
                <div className="search-hero-text-section">
                    <h2 className="search-hero-hero-title">Otkrijte savršenu nekretninu</h2>
                    <p className="search-hero-hero-paragraph">Širok izbor nekretnina na jednome mjestu</p>
                </div>
                <div className="search-hero-image-section">
                    <div className="search-hero-option-buttons">
                        <button className={`search-hero-option-button ${selectedOption === 'Najam' ? 'selected' : ''}`} onClick={() => setSelectedOption('Najam')}>Najam</button>
                        <button className={`search-hero-option-button ${selectedOption === 'Prodaja' ? 'selected' : ''}`} onClick={() => setSelectedOption('Prodaja')}>Prodaja</button>
                    </div>
                </div>
            </div>
            <div className="search-hero-search-overlay">
                <div className="search-hero-search-bar-container">
                    <input className="search-hero-search-bar" type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Unesite pojam..." />
                    <Link
                        to={{pathname: "/pretraga",
                            search: `?gradovi=${searchValue}&kategorije=${selectedOption.toUpperCase()}`,
                            hash:  "rezultati-pretrage-id"}}>
                        <button className="search-hero-search-button">Pretraga</button>
                    </Link>
                </div>
                <div className="search-hero-recommendations-container">
                    <button className="search-hero-scroll-button left" onClick={() => document.getElementById('recommendations-list').scrollLeft -= 100}>
                        <Icon name="arrow left" />
                    </button>
                    <div className="search-hero-recommendations-list" id="recommendations-list">
                        {recommendations.map((item, index) => (
                            <div
                                key={index}
                                className={`search-hero-recommendation-item ${item === searchValue ? 'selected' : ''}`}
                                onClick={() => setSearchValue(item)}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                    <button className="search-hero-scroll-button right" onClick={() => document.getElementById('recommendations-list').scrollLeft += 100}>
                        <Icon name="arrow right" />
                    </button>
                </div>
            </div>
        </>
    );
};

export default SearchHeroDesktop;
